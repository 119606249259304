import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    expanded: 'panel1',
    showMenu: false
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        expandNode: (state, { payload }) => {
            state.expanded = payload;
        },
        menuToggle: (state, { payload }) => {
            state.showMenu = payload;
        }
    }
});

export const ExpandedSelector = (state) => {
    return state.navigation.expanded;
};

export const MenuSelector = (state) => {
  return state.navigation.showMenu;
};

export const { expandNode, menuToggle } = navigationSlice.actions;

export default navigationSlice.reducer;