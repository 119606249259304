import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints';
import { GetMethod, PostMethod } from "../../Api/Methods/apiMethods";
import { itemPerPage } from "data/constants";

export const GetAllBuildingRoom = createAsyncThunk("buildingrooms/getallbuildingrooms",
    async (_ ,thunkAPI) => {
        const { filter } = thunkAPI.getState().buildingrooms;
        try {
            let res = await GetMethod(EndPoints.getAllBuilding)
            let RoomByBuildingID = [];
            let currentID = res.data.data[0].id ?? null
            if (res.status == 200) RoomByBuildingID = await PostMethod(EndPoints.getRoomByBuilding(currentID), {
                "availableCount": 0,
                "pageSize": itemPerPage,
                "roomNumber": filter?.room ?? "",
                "roomTypeName": filter?.roomType ?? ""
              })
            return {
                data: res.data.data,
                id: currentID,
                currentRooms: RoomByBuildingID?.data?.data,

            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });

        }
    })

export const GetRoomByBuilding = createAsyncThunk("buildingrooms/getbuildingroomsbyBuilding", async ({ id,  filter }, thunkAPI) => {
    const { filter : storedFilter } = thunkAPI.getState().buildingrooms;
    try {
        let res = await PostMethod(EndPoints.getRoomByBuilding(id), {
            "availableCount": 0,
            "pageSize": itemPerPage,
            "roomNumber": filter?.room ?? storedFilter.room,
            "roomTypeName": filter?.roomType ?? storedFilter.roomType
          })
        return res?.data?.data 
    }
    catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }

})

export const GetNextRoomsByBuilding = createAsyncThunk("buildingrooms/getNextbuildingroomsbyBuilding", async (id, thunkAPI) => {
    const { RoomsbyBuilding, filter } = thunkAPI.getState().buildingrooms;
    try {
        let res = await PostMethod(EndPoints.getRoomByBuilding(id), {
            "availableCount": RoomsbyBuilding.length,
            "pageSize": itemPerPage,
            "roomNumber": filter.room,
            "roomTypeName": filter.roomType
          })
        return res?.data?.data 
    }
    catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }

})

export const GetBuildingFloor = createAsyncThunk("buildingrooms/getBuildingFloor", async (id, thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAvailableBuildingFloor)
        return res.data.data
    }
    catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }

})

export const GetAllCostCenter = createAsyncThunk("buildingrooms/GetCostCenter", async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAllCostCenter)
        return res.data.data
    }
    catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }

})

const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    currentBuildingID: 0,
    BuildingIdforAdd: 0,
    Buildings: [],
    BuildingRooms: [],
    CostCenters: [],
    BuildingFloors: [],
    RoomsbyBuilding: [],
    Users: [],
    UpdateError: false,
    UpdateBuildingRoom: {

    },
    RoomsbyBuildingTotalCount : 0,
    isInfiniteLoading : false,
    filter : { room: "", roomType: "" }
}

export const buildingroomsSlice = createSlice({
    name: "buildingrooms",
    initialState,
    reducers: {
        setCurrentBuilding: (state, action) => {
            state.currentBuildingID = action.payload
        },
        openDialog: (state) => {
            state.open = true
        },
        closeDialog: (state) => {
            state.open = false
        },
        EditbuildingRooms: (state, { payload }) => {
            state.RoomsbyBuilding = state.RoomsbyBuilding.map((item => item.id === payload.data.id ? payload.data : item))
            state.UpdateBuildingRoom[payload.data.id] = { id: payload.data.id, ...payload.data };
        },
        ClearUpdate: (state) => {
            state.UpdateBuildingRoom = {}
        },
        ToggleUpdateError: (state, action) => {
            state.UpdateError = action.payload
        },
        EditFilter : (state, { payload }) => {
            state.filter = payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllBuildingRoom.fulfilled, (state, { payload }) => {
                state.currentBuildingID = payload.id
                state.isGetAllLoading = false
                state.Buildings = payload.data
                state.RoomsbyBuilding = payload.currentRooms?.data ?? []
                state.RoomsbyBuildingTotalCount = payload.currentRooms?.count ?? 0
            })
            .addCase(GetAllBuildingRoom.pending, (state) => {
                state.isGetAllLoading = true
            })
            .addCase(GetAllBuildingRoom.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetRoomByBuilding.fulfilled, (state, { meta, payload }) => {
                state.RoomsbyBuilding = payload?.data ?? []
                state.RoomsbyBuildingTotalCount = payload?.count ?? 0
                state.isLoading = false
            })
            .addCase(GetRoomByBuilding.pending, (state, { meta, payload }) => {
                state.isLoading = true
            })
            .addCase(GetRoomByBuilding.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(GetNextRoomsByBuilding.fulfilled, (state, { meta, payload }) => {
                state.RoomsbyBuilding = payload?.data?.length ? [ ...state.RoomsbyBuilding,  ...payload?.data ] : state.RoomsbyBuilding
                state.RoomsbyBuildingTotalCount = payload?.count ?? 0
                state.isInfiniteLoading = false
            })
            .addCase(GetNextRoomsByBuilding.pending, (state, { meta, payload }) => {
                state.isInfiniteLoading = true
            })
            .addCase(GetNextRoomsByBuilding.rejected, (state, action) => {
                state.isInfiniteLoading = false
            })
            .addCase(GetBuildingFloor.fulfilled, (state, { meta, payload }) => {
                state.BuildingFloors = payload
            })
            .addCase(GetAllCostCenter.fulfilled, (state, { meta, payload }) => {
                state.CostCenters = payload
            })
    }

});

//Selectors

export const BuildingRoomsSelector = (state) => state.buildingrooms


export const { openDialog, closeDialog, EditbuildingRooms, ClearUpdate, ToggleUpdateError, setCurrentBuilding, EditFilter } = buildingroomsSlice.actions;


export default buildingroomsSlice.reducer;
