import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";
import { closeModel } from "Redux/Model/ModelSlice";

export const GetIndividualRoomDetailById = createAsyncThunk(
  "Individualroomdetails/getIndividualRoomDetailById",
  async (_, thunkAPI) => {
    const { filterData } = thunkAPI.getState().IndividualRoomDetails;
    try {
      const { data } = await GetMethod(
        EndPoints.getIndividualRoomDetailById(filterData?.roomId)
      );
      return data.data;
    } catch (err) {
      return err;
    }
  }
);

export const GetAllRoomTypes = createAsyncThunk(
  "Individualroomdetails/getAllRoomTypes",
  async () => {
    try {
      const { data } = await GetMethod(EndPoints.getAllAvailableTask);
      return data.data;
    } catch (err) {
      return err;
    }
  }
);

export const GetRoomByBuilding = createAsyncThunk(
  "Individualroomdetails/getbuildingroomsbyBuilding",
  async ({ id, onClose }, thunkAPI) => {
    try {
      let res = await GetMethod(EndPoints.getRoomByBuilding(id));
      onClose();
      if (res.data.isSuccess) {
        return res.data.data ?? [];
      } else {
        thunkAPI.dispatch(
          closeModel({
            open: true,
            message: "No rooms under the building",
            severity: "error",
          })
        );
        return [];
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const GetAvailableBuilding = createAsyncThunk(
  "Individualroomdetails/getAvailableBuilding",
  async () => {
    try {
      const [availableBuilding] =
        await Promise.all([
          GetMethod(EndPoints.getAvailableBuilding)
        ]);
      return {
        availableBuilding: availableBuilding.data.data,
      };
    } catch (err) {
      return err;
    }
  }
);
const initialState = {
  Individualroomdetails: [],
  buildings: [],
  selectedRoomById: {},
  rooms: [],

  filterData: {},
  roomTypes: [],

  showTable: false,
  tableLoading: false,
  addNewLoading: false,
};

export const IndividualRoomDetailsSlice = createSlice({
  name: "Individualroomdetails",
  initialState,
  reducers: {
    setShowTable: (state) => {
      state.showTable = true;
    },
    setFilterData: (state, { payload }) => {
      state.filterData[payload.id] = payload.value;
    },
    updateIndividualroomdetails: (state, { payload }) => {
      state.Individualroomdetails = payload.updatedData;
    },
    setSelectedRoomById: (state, { payload }) => {
      state.selectedRoomById = payload;
    },
    setIndividualroomdetailsInitialState: (state) => {
      state.Individualroomdetails = [];
      state.buildings = [];
      state.rooms = [];
      state.filterData = {};
      state.selectedRoomById = {};
      state.roomTypes = [];
      state.showTable = false;
      state.tableLoading = false;
      state.addNewLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllRoomTypes.fulfilled, (state, action) => {
        state.roomTypes = action.payload;
        state.addNewLoading = false;
      })
      .addCase(GetAllRoomTypes.rejected, (state, action) => {
        state.addNewLoading = false;
      })
      .addCase(GetAllRoomTypes.pending, (state) => {
        state.addNewLoading = true;
      })
      .addCase(GetAvailableBuilding.fulfilled, (state, action) => {
        state.buildings = action.payload.availableBuilding;
      })
      .addCase(GetAvailableBuilding.rejected, (state, action) => { })
      .addCase(GetAvailableBuilding.pending, (state) => { })
      .addCase(GetIndividualRoomDetailById.fulfilled, (state, action) => {
        state.Individualroomdetails = action.payload;
        state.tableLoading = false;
      })
      .addCase(GetIndividualRoomDetailById.rejected, (state, action) => {
        state.tableLoading = false;
      })
      .addCase(GetIndividualRoomDetailById.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(GetRoomByBuilding.fulfilled, (state, { meta, payload }) => {
        state.rooms = payload;
      })
      .addCase(GetRoomByBuilding.pending, (state, { meta, payload }) => { })
      .addCase(GetRoomByBuilding.rejected, (state, action) => { });
  },
});

export const IndividualRoomDetailsSelector = (state) =>
  state.IndividualRoomDetails;

export const {
  setFilterData,
  setShowTable,
  setIndividualroomdetailsInitialState,
  updateIndividualroomdetails,
  setSelectedRoomById
} = IndividualRoomDetailsSlice.actions;

export default IndividualRoomDetailsSlice.reducer;
