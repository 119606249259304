import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod, PostMethod } from "../../Api/Methods/apiMethods";

export const GetAllReportDetails = createAsyncThunk(
    "ReportDetails/GetAllReportTypes",
    async (_, thunkAPI) => {
        try {
            const res = await PostMethod(EndPoints.getAllReportDetails);
            return res.data.data ?? {}

        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const initialState = {
  reportDetails: [],
  isLoading: false,
  currentReportId: 0,
};

export const ReportDetailsSlice = createSlice({
  name: "ReportDetails",
  initialState,
  reducers: {
    SortReportDetails: (state, { payload }) => {
      state.reportDetails = payload;
    },
    SetCurrentReportId: (state, { payload }) => {
      state.currentReportId = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllReportDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllReportDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.reportDetails = payload || [];
      })
      .addCase(GetAllReportDetails.rejected, (state) => {
        state.isLoading = false;
        state.reportDetails = [];
      });
  },
});


//Selectors
export const ReportDetailsSelector = (state) => state.reportDetails;

//Actions
export const { SortReportDetails, SetCurrentReportId } = ReportDetailsSlice.actions;

//Reducer
export default ReportDetailsSlice.reducer;