import { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// my routes
import { myRoutes } from "../components/Routes/routes";
import { getModulePermissions } from 'Redux/RolePermissions/RolePermissionsSlice'
import PreLoader from "components/ui/preLoader/PreLoader";

const SignInSide = lazy(() => import('../features/authentication/components/LoginForm')); 
const ForgetPassword = lazy(() => import( '../features/authentication/components/ForgetPassword'));
const ResetPassword = lazy(() => import( '../features/authentication/components/ResetPassword'));
const PasswordResetSuccess = lazy(() => import( '../features/authentication/components/PasswordResetSuccess'));
const TokenValidation = lazy(() => import( '../features/authentication/components/TokenValidation'));
const Layout = lazy(() => import( "../layouts"));
const SetPassword = lazy(() => import( "features/authentication/components/SetPassword"));


function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let authToken = localStorage.getItem('token')
    if (!authToken) {
      navigate("/login")
    }
    else {
      dispatch(getModulePermissions());
    }

  }, [])

  return (
    <Suspense fallback={<PreLoader elementLoader={true} loaderSize={1.5}  />}>
      <Routes>
           <Route exact path="/" element={<SignInSide/>} />
           <Route path={myRoutes.login} element={<SignInSide/>} />
           <Route path={myRoutes.forgotpassword} element={<ForgetPassword/>} />
           <Route path={myRoutes.resettoken} element={<TokenValidation tokenValidate={"forgotPasswordValidate"} onSucess={myRoutes.resetpassword}/>} />
           <Route path={myRoutes.invite} element={<TokenValidation  tokenValidate={"inviteTokenValidate"}  onSucess={myRoutes.setPassword}/>} />
           <Route path={myRoutes.resetpassword} element={<ResetPassword/>} />
           <Route path={myRoutes.setPassword} element={<SetPassword/>} />
           <Route path={myRoutes.success} element={<PasswordResetSuccess/>} />
           <Route path={myRoutes.home} element={<Layout/>} />
           {/* <Route path={myRoutes.nopage} element={<Navigate to="/login" replace />} /> */}
      </Routes>
    </Suspense>
  );
}

export default LoginPage;