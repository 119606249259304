import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";

export const GetAvailableBuilding = createAsyncThunk(
  "InspectRooms/GetAvailableBuilding",
  async () => {
    try {
      const { data } = await GetMethod(EndPoints.getInspectRoomsBuildings);
      return { building: data.data, selectedBuildingId: data.data[0].id };
    } catch (err) {
      return err;
    }
  }
);

export const GetInspectRoomsbByBuilding = createAsyncThunk(
  "InspectRooms/getEditUserValue",
  async (id) => {
    try {
      const { data } = await GetMethod(EndPoints.getInspectRoomsbByBuilding(id));
      return data.data;
    } catch (err) {
      return err;
    }
  }
);
const initialState = {
  isLoadingDropdown: false,
  isLoadingTabel: false,
  building: [],
  inspectRooms: [],

  selectedBuildingId: '',

  error: false,
};

export const InspectRoomsSlice = createSlice({
  name: "InspectRooms",
  initialState,
  reducers: {
    setSelectedBuilding: (state, { payload }) => {
      state.selectedBuildingId = payload;
    },
    updateInspectRoom: (state, { payload }) => {
      state.inspectRooms = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAvailableBuilding.fulfilled, (state, { payload }) => {
        state.building = payload.building;
        state.selectedBuildingId = payload.selectedBuildingId;
        state.isLoadingDropdown = false;
        state.error = false;
      })
      .addCase(GetAvailableBuilding.rejected, (state, action) => {
        state.error = true;
        state.isLoadingDropdown = false;
      })
      .addCase(GetAvailableBuilding.pending, (state) => {
        state.isLoadingDropdown = true;
      })
      .addCase(GetInspectRoomsbByBuilding.fulfilled, (state, action) => {
        state.inspectRooms = action.payload;
        state.isLoadingTabel = false;
        state.error = false;
      })
      .addCase(GetInspectRoomsbByBuilding.rejected, (state, action) => {
        state.isLoadingTabel = false;
      })
      .addCase(GetInspectRoomsbByBuilding.pending, (state) => {
        state.isLoadingTabel = true;
      });
  },
});

export const inspectRoomsSelector = (state) => state.InspectRooms;

export const { updateInspectRoom, setSelectedBuilding } = InspectRoomsSlice.actions;

export default InspectRoomsSlice.reducer;
