import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetProcedureTask } from "Redux/RoomStandards/RoomStandardsSlice";
import { setNewEditObject } from "Redux/RoomStandards/RoomStandardsSlice";
import { roomStandardsSelector, setAddnewId } from "Redux/RoomStandards/RoomStandardsSlice";
import AppCheckbox from "../../../components/form/AppCheckbox";
import AppSelect from "../../../components/form/AppSelect";
import FormInput from "../../../components/form/FormInput";
import "./add-new.scss";

function AddNew() {
  const {
    RoomTypes,
    newEditObject,
    procedureTask,
    addNewError,
    selectedRoomTypeId,
    AddnewId } = useSelector(roomStandardsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetProcedureTask());
    dispatch(setNewEditObject({ key: "roomTypeId", value: selectedRoomTypeId }))
  }, [selectedRoomTypeId]);

  const handleSelect = (e, newValue) => {
    dispatch(
      setNewEditObject({ key: "roomTypeId", value: newValue ? newValue.id : e.target.value })
    );
    dispatch(
      setAddnewId(newValue ? newValue.id : e.target.value)
    );
  }

  return (
    <Grid
      container
      className="add-new-campus modal-form-grid"
      direction={"row"}
      rowSpacing={1}
      columnSpacing={3}
    >
      <Grid item sm={6} xs={12} className="modal-form-cell">
        <AppSelect
          error={addNewError["roomTypeId"]?.iserror ?? false}
          helperText={addNewError["roomTypeId"]?.error ?? ""}
          labelName="Choose Room Type"
          labelVisible={true}
          menuItems={RoomTypes}
          selectOption={AddnewId ?? null}
          autoComplete
          handleSelectChange={handleSelect}
        />
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}
        className="modal-form-cell"
        container
        alignItems="flex-end"
      >
        <AppSelect
          error={addNewError["procedureTaskId"]?.iserror ?? false}
          helperText={addNewError["procedureTaskId"]?.error ?? null}
          labelName="Room Standard"
          labelVisible={true}
          menuItems={procedureTask}
          selectOption={!newEditObject["procedureTaskId"] ? procedureTask[0]?.id : newEditObject["procedureTaskId"]}
          autoComplete
          handleSelectChange={(e, newValue) =>
            dispatch(
              setNewEditObject({
                key: "procedureTaskId",
                value: newValue ? newValue.id : e.target.value,
              })
            )
          }
        />
      </Grid>
      <Grid item sm={3} xs={12} className="modal-form-cell">
        <FormInput
          type="number"
          labelName="Order"
          error={addNewError["order"]?.iserror ?? false}
          helperText={addNewError["order"]?.error ?? null}
          value={newEditObject["order"] ?? ""}
          InputProps={{ inputProps: { min: 1 } }}
          onInputChange={(e) =>
            dispatch(
              setNewEditObject({
                key: "order",
                value: parseInt(e.target.value),
              })
            )
          }
        />
      </Grid>
      <Grid item sm={3} xs={12} className="modal-form-cell">
        <FormInput
          error={addNewError["noOfUnits"]?.iserror ?? false}
          helperText={addNewError["noOfUnits"]?.error ?? null}
          type="number"
          labelName="No of Units"
          value={newEditObject["noOfUnits"] ?? ""}
          InputProps={{ inputProps: { min: 0 } }}
          onInputChange={(e) =>
            dispatch(
              setNewEditObject({
                key: "noOfUnits",
                value: parseInt(e.target.value),
              })
            )
          }
        />
      </Grid>
      <Grid item sm={6} xs={12} className="modal-form-cell days-row">
        {[
          { name: "level2", label: "L2" },
          { name: "level3", label: "L3" },
        ].map(( el, index ) => (
          <Box component="div" key={index} className="checkbox-label-days">
            <AppCheckbox
              handleChange={(e) =>
                dispatch(
                  setNewEditObject({
                    key: el.name,
                    value: e.target.checked,
                  })
                )
              }
              check={newEditObject[el.name] ?? false}
            />
            <Typography component="span">{el.label}</Typography>
          </Box>
        ))}
      </Grid>

      <Grid item xs={12} className="modal-form-cell days-row">
        {[
          { name: "monday", label: "MON" },
          { name: "tuesday", label: "TUE" },
          { name: "wednesday", label: "WED" },
          { name: "thursday", label: "THU" },
          { name: "friday", label: "FRI" },
          { name: "saturday", label: "SAT" },
          { name: "sunday", label: "SUN" },
        ].map((el, index) => (
          <Box component="div" key={index} className="checkbox-label-days">
            <AppCheckbox
              handleChange={(e) =>
                dispatch(
                  setNewEditObject({
                    key: el.name,
                    value: e.target.checked,
                  })
                )
              }
              check={newEditObject[el.name] ?? false}
            />
            <Typography component="span">{el.label}</Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Box component="div" className="form-info">
          <FontAwesomeIcon icon={faCircleInfo} className="form-info-icon" />
          <Typography>
            L2 - Level 2 cleaning @75% labour hours available
          </Typography>
        </Box>

        <Box component="div" className="form-info">
          <FontAwesomeIcon icon={faCircleInfo} className="form-info-icon" />
          <Typography>
            L3 - Level 3 cleaning @50% labour hours available
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddNew;