import React from 'react';
import { Typography, Box } from '@mui/material';
import './page-title.scss';

function PageTitle({pageTitle, pageRightSection,utility}) {
    return (
        <Box className='page-title'>
        {pageTitle ? <Typography component='h3'>{pageTitle}{utility}</Typography>: <Box></Box>}
            {pageRightSection && <Box className='page-right'>
                {pageRightSection}
            </Box>}
        </Box>
    )
}

export default PageTitle