import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";
import { closeModel as closeDialog } from "Redux/Model/ModelSlice";


export const GetAvailableBuilding = createAsyncThunk(
    "InspectionDetailsByRoom/getAvailableBuilding",
    async (_, thunkAPI) => {
        try {
            const [availableBuilding] =
                await Promise.all([
                    GetMethod(EndPoints.getAvailableBuilding)
                ]);
            return {
                availableBuilding: availableBuilding.data.data ?? [],
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const GetBuildingRoomByBuilding = createAsyncThunk(
    "InspectionDetailsByRoom/getBuildingRoomByBuilding",
    async ({ id, onClose }, thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getRoomByBuilding(id))
            onClose()
            if (res.data.isSuccess) {
                return res.data.data ?? []
            } else {
                thunkAPI.dispatch(
                    closeDialog({
                        open: true,
                        message: "No rooms under the building",
                        severity: "error",
                    })
                );
            }
        } catch (err) {
            return err;
        }
    }
);
export const GetInspectionDetailsbyRoom = createAsyncThunk(
    "InspectionDetailsByRoom/getInspectionDetailsbyRoom",
    async (id, thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getInspectionRoom(id));
            if (res.data.isSuccess) return res?.data?.data?.sort((a, b) => (a.order - b.order)) ?? [];
            return [];
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);
export const GetScale = createAsyncThunk(
    "InspectionDetailsByRoom/getScale",
    async () => {
        try {
            let res = await GetMethod(EndPoints.getInspectionScale)
            return res.data.data ?? []
        } catch (err) {
            return err;
        }
    }
);

export const GetAvailableInspectionCategories = createAsyncThunk(
    "InspectionDetailsByRoom/getAvailableInspectionCategories",
    async () => {
        try {
            let res = await GetMethod(EndPoints.getAllAvailableInspectionCategoryItem)
            return res.data.data ?? []
        } catch (err) {
            return err;
        }
    }
);


const initialState = {
    buildings: [],
    selectedRoomById: {},
    rooms: [],
    currentRoomId: null,
    InspectionItemByRoom: [],
    isLoading: false,
    isError: false,
    loadTable: false,
    availableCategoryItem: [],
    filterData: {},
    scale: [],

    InspectionCategoryItem: [],
    isAddNewModelLoading: false,

    isEdit: false,
    editData: {},
    open: false,

    UpdateRoomDetails: {}
};

export const InspectionDetailsByRoomSlice = createSlice({
    name: "InspectionDetailsByRoom",
    initialState,
    reducers: {
        setFilterData: (state, { payload }) => {
            state.filterData[payload.id] = payload.value;
        },
        setEditFalse: (state, action) => {
            state.isEdit = false;
            state.editData = {};
        },
        seteditData: (state, action) => {
            state.editData = action.payload
            state.open = true
            state.isEdit = true
        },
        setSelectedRoomById: (state, { payload }) => {
            state.selectedRoomById = payload;
        },
        setcurrentRoomId: (state, { payload }) => {
            state.currentRoomId = payload;
        },
        UpdateAllRoomDetails: (state, { payload }) => {
            state.InspectionItemByRoom = payload;
        },
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditisAvailable: (state, { payload }) => {
            state.InspectionItemByRoom = state.InspectionItemByRoom?.map((item => item.inspectionCategoryItemId === payload.data.inspectionCategoryItemId ? payload.data : item))
            state.UpdateRoomDetails[payload.data.inspectionCategoryItemId] = { id: payload.data.inspectionCategoryItemId, ...payload.data };
        },
        setInitialState: (state) => {
            state.buildings = [];
            state.selectedRoomById = {};
            state.rooms = [];
            state.currentRoomId = null;
            state.InspectionItemByRoom = [];
            state.isLoading = false;
            state.isError = false;
            state.loadTable = false;
            state.availableCategoryItem = [];
            state.filterData = {};
            state.scale = [];
            state.InspectionCategoryItem = [];
            state.isAddNewModelLoading = false;
            state.isEdit = false;
            state.editData = {};
            state.open = false;
            state.UpdateRoomDetails = {}
        }
    },
    extraReducers(builder) {
        builder.addCase(GetAvailableBuilding.fulfilled, (state, action) => {
            state.buildings = action.payload.availableBuilding;
        })
            .addCase(GetAvailableBuilding.rejected, (state, action) => {

            })
            .addCase(GetAvailableBuilding.pending, (state) => {

            })
            .addCase(GetBuildingRoomByBuilding.fulfilled, (state, action) => {
                state.rooms = action.payload;
                state.selectedRoomById = {}
                state.currentRoomId = 0
                state.InspectionItemByRoom = null
            })
            .addCase(GetBuildingRoomByBuilding.rejected, (state, action) => { })
            .addCase(GetBuildingRoomByBuilding.pending, (state) => { })
            .addCase(GetInspectionDetailsbyRoom.fulfilled, (state, action) => {
                state.InspectionItemByRoom = action.payload;
                state.isLoading = false
            })
            .addCase(GetInspectionDetailsbyRoom.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(GetInspectionDetailsbyRoom.pending, (state) => {

                state.isLoading = true
            })
            .addCase(GetScale.fulfilled, (state, action) => {
                state.scale = action.payload;
            })
            .addCase(GetScale.rejected, (state, action) => { })
            .addCase(GetScale.pending, (state) => { })
            .addCase(GetAvailableInspectionCategories.fulfilled, (state, action) => {
                state.availableCategoryItem = action.payload;
            })
            .addCase(GetAvailableInspectionCategories.rejected, (state, action) => { })
            .addCase(GetAvailableInspectionCategories.pending, (state) => { })
    },
});

export const RoomInspectionDetailsSelector = (state) =>
    state.InspectionDetailsByRoom;

export const { setFilterData, setInitialState, setSelectedRoomById, UpdateAllRoomDetails, openModel, closeModel, seteditData, setEditFalse, setcurrentRoomId, EditisAvailable } = InspectionDetailsByRoomSlice.actions;

export default InspectionDetailsByRoomSlice.reducer;
