export const JsonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

export const GetAuthorizationToken = () => localStorage.getItem('token');

export const SetAuthorization = (token) => {
    localStorage.setItem("token", token);
};
export const saveLoginToken = (token) => {
    localStorage.setItem("logintkn", token);
};

export const ClearStorage = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userID");
    sessionStorage.removeItem("success");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("uname");
    localStorage.clear();
};
