import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";

const getallhightouchsurfacesApi = async () => {
  try {
    let res = await GetMethod(EndPoints.getAllAvailableHighTouchSurface)
    return res.data
  } catch (error) {
    return error;
  }
}


const GetAllRoomTypeApi = async () => {
  try {
    let res = await GetMethod(EndPoints.getAllAvailableRoomType)
    return res.data
  } catch (error) {
    return error;
  }
}


export const GetAllRoomTypeAndHighTouchSurface = createAsyncThunk(
  "touchsurface/getallroomtypeAndHighTouchSurface",
  async () => {
    try {
      const [roomType, highTouchSurface] = await Promise.all([
        GetAllRoomTypeApi(),
        getallhightouchsurfacesApi(),
      ]);
      return {
        roomTypes: roomType.data,
        highTouchSurface: highTouchSurface.data,
      };
    } catch (err) {

      return err;
    }
  }
);
export const GetHighTouchSurfaceByRoomId = createAsyncThunk(
  "touchsurface/gethightouchsurfacebyroomid",
  async (id, thunkAPI) => {
    try {
      const { highTouchSurface } = thunkAPI.getState().touchsurface;
      let availableHighTouchSurfaceId = highTouchSurface.map(({ id }) => id);
      thunkAPI.dispatch(setSelectedRoomType(id));
      const res = await GetMethod(EndPoints.RoomTypeHightouchSurfaceById(id));
      if(res.data.data) return res.data.data.hightouchSurfaces.filter((item) => availableHighTouchSurfaceId.includes(item.id));
      return [];
    } catch (err) {

      return err;
    }
  }
);

const initialState = {
  roomTypes: [],
  highTouchSurface: [],
  isLoading: true,
  selectedHighTouchSurface: [],
  selectedRoomType: null,
  isSelectedRoomTypeLoading: false
};

export const touchsurfaceSlice = createSlice({
  name: "touchsurface",
  initialState,
  reducers: {
    setSelectedHighTouchSurface: (state, { payload }) => {
      state.selectedHighTouchSurface = payload;
    },
    setSelectedRoomType: (state, { payload }) => {
      state.selectedRoomType = payload;
    },
    clearTouchSurfaces: (state) => {
      state.highTouchSurface = initialState.highTouchSurface;
      state.selectedHighTouchSurface = initialState.selectedHighTouchSurface;
      state.selectedRoomType = initialState.selectedRoomType;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllRoomTypeAndHighTouchSurface.fulfilled, (state, action) => {
        state.roomTypes = action.payload.roomTypes;
        state.highTouchSurface = action.payload.highTouchSurface;
        state.isLoading = false;
      })
      .addCase(GetAllRoomTypeAndHighTouchSurface.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(
        GetHighTouchSurfaceByRoomId.pending,
        (state) => {
          state.isSelectedRoomTypeLoading = true;
        }
      )
      .addCase(
        GetHighTouchSurfaceByRoomId.fulfilled,
        (state, { payload }) => {
          state.isSelectedRoomTypeLoading = false;
          state.selectedHighTouchSurface =
            payload?.map(({ id }) => id) ?? [];
        }
      )
      .addCase(GetHighTouchSurfaceByRoomId.rejected, (state) => {
        state.isSelectedRoomTypeLoading = false;
      });
  },
});

//Selectors

export const touchsurfaceSelector = (state) => state.touchsurface;

export const { setSelectedHighTouchSurface, setSelectedRoomType, clearTouchSurfaces } =
  touchsurfaceSlice.actions;

export default touchsurfaceSlice.reducer;
