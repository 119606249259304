import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import AppButton from '../../components/ui/buttons/AppButton';
//import AppModal from '../../components/ui/modal/AppModal';
//import AddNewCampus from '../campus/AddNewCampus/AddNewCampus';
import './page-header.scss';
import { Stack, Box } from '@mui/material';
import AppSelect from 'components/form/AppSelect';
import { useSelector, useDispatch } from 'react-redux';
import { CampusSelector, setcampusID, setCampusname, setcampusLocation, setSelectedID, setCampusToken, SwitchAMPM } from '../../Redux/Campus/campusSlice';
import { SetAuthorization } from 'Api/Headers/headers';
import axios from 'axios';

import { EndPoints } from 'Api/Endpoints/endPoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function PageHeader({ headerTitle, headerSubTitle, headerRight, iscampusDropdown }) {

  const dispatch = useDispatch()
  const { SelectId, campusArray } = useSelector(CampusSelector)

  let selectedID = localStorage.getItem("selectid")

  const [Campus, setCampus] = useState([])
  const [subCampus, setsubCampus] = useState([])
  let defaultCampus = Campus ?? []

  const MainToken = localStorage.getItem('logintkn')
  const config = { headers: { 'Authorization': `Bearer ${MainToken}` } }



  useEffect(() => {
    setTimeout(() => {
      if (!selectedID) {
        localStorage.setItem('selectid', SelectId)
      }
    }, 5000)
  }, []);


  const categoriesSelecbyId = async (id, config) => {
    try {
      const res = await axios.get(EndPoints.selectCategory(id), config)
      SetAuthorization(res.data.data)
      dispatch(setCampusToken(res.data.data))

    } catch (error) {
      console.log(error)
    }
  }

  const handleSelect = (e, config) => {
    dispatch(setSelectedID(e.target.value))
    localStorage.setItem('selectid', e.target.value)
    categoriesSelecbyId(e.target.value, config)
    let selectedCampus = campusArray.find(x => x.id === e.target.value)
    setsubCampus(selectedCampus.subCampus)
    dispatch(SwitchAMPM(selectedCampus.timeFormat == '12' ? true : false))
    dispatch(setCampusname(selectedCampus.name))
    dispatch(setcampusLocation(selectedCampus.location))

    sessionStorage.setItem(
      "campusName", selectedCampus.name
    );
    sessionStorage.setItem(
      "location", selectedCampus.location
    );
  }

  return (
    <Grid container className='page-header'>
      <Grid item xs className='page-header-left'>
        <Stack direction="row" spacing={3}>
          {iscampusDropdown ? <Box className="green-select"><AppSelect
            defaultValue={defaultCampus[0]?.id}
            selectOption={SelectId === 0 ? selectedID : SelectId}
            menuItems={campusArray}
            handleSelectChange={(e) => handleSelect(e, config)}
          /> </Box> : <Typography component='h2'>{headerTitle}</Typography>
          }
          {!subCampus.length === 0 && <Box className="green-select"><AppSelect
            selectOption={SelectId === 0 ? selectedID : SelectId}
            menuItems={subCampus}
            handleSelectChange={(e) => handleSelect(e, config)}
          /></Box>}</Stack>
        {headerSubTitle && <Typography component='h6'><FontAwesomeIcon icon={faLocationDot} className='head-location-icon' />{headerSubTitle}</Typography>}
      </Grid>
      <Grid item xs className='page-header-right'>
        {headerRight}
      </Grid>
    </Grid >
  )
}

export default PageHeader