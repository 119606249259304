import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AppCheckbox from '../../../components/form/AppCheckbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './table-component.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { roomStandardsSelector } from 'Redux/RoomStandards/RoomStandardsSlice';
import { Loading } from '../RoomStandards';
import { UpdateAllRoomStandard } from 'Redux/RoomStandards/RoomStandardsSlice';
import { UpdateRoomStandard } from 'Redux/RoomStandards/RoomStandardsSlice';
import { setUpate } from 'Redux/Update/UpdateSlice';
import { setUpateObject } from 'Redux/Update/UpdateSlice';
import { t } from 'i18next';
import { DeleteMethod, PutMethod } from 'Api/Methods/apiMethods';
import { EndPoints } from 'Api/Endpoints/endPoints';
import { openModel } from 'Redux/Model/ModelSlice';
import { closeModel } from 'Redux/Model/ModelSlice';
import { GetRoomStandards } from 'Redux/RoomStandards/RoomStandardsSlice';
import { store } from 'Redux/store';
import { clearUpate } from 'Redux/Update/UpdateSlice';
import { RolePermissionsSelector } from 'Redux/RolePermissions/RolePermissionsSlice'
import { Modules } from 'data/constants';

async function BulkUpdateRoomStandard() {
    let { update } = store.getState().update;
    let modelCloseObj = closeModel({
        open: true,
        message: t('RoomStandard.update-error'),
        severity: "error",
    })
    try {
        let roomTypeid;
        let body = Object.values(update.object);
        roomTypeid = body[0].roomTypeId;
        let res = await PutMethod(
            EndPoints.bulkPutRoomTypeTask(roomTypeid),
            JSON.stringify(body)
        );
        if (!res.data.isSuccess) { store.dispatch(modelCloseObj); return };
        store.dispatch(GetRoomStandards({ id: roomTypeid }));
        store.dispatch(
            closeModel({
                open: true,
                message: t('RoomStandard.update-success'),
                severity: "success",
            })
        );
        store.dispatch(clearUpate());
    } catch (error) {
        console.log(error);
        store.dispatch(modelCloseObj);
    }
}
function TableComponent() {
    const { roomStandard, isroomStandardTableLoading, selectedRoomTypeId } = useSelector(roomStandardsSelector);
    const dispatch = useDispatch();
    const { allModulePermissions } = useSelector(RolePermissionsSelector)

    const UpdateModelObj = {
        open: true,
        type: "UPDATE",
        onClickSave: () => BulkUpdateRoomStandard(),
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result.map((item, index) => ({ ...item, order: index + 1 }));
    };

    const onEnd = (result) => {
        let updateArray = reorder(roomStandard, result.source.index, result.destination.index);
        dispatch(UpdateAllRoomStandard(updateArray));
        dispatch(setUpate({
            object: Object.fromEntries(updateArray.map(item => [item.id, item])),
            isAnyUpdate: true,
            modelData: UpdateModelObj
        }));
    }

    const updateState = (index, updatedData) => {
        dispatch(UpdateRoomStandard({ index, updatedData }));
        dispatch(setUpateObject({ data: updatedData, modelData: UpdateModelObj }));
    }

    const DeleteRoomStandard = async (id) => {
        try {
            let res = await DeleteMethod(EndPoints.deleteRoomTypeTask(id));
            dispatch(GetRoomStandards({ id: selectedRoomTypeId }));
            dispatch(closeModel({
                open: true,
                message: t('RoomStandard.delete-success'),
                severity: "success",
            }));
        } catch (error) {
            console.log(error);
            dispatch(closeModel({
                open: true,
                message: t('RoomStandard.delete-error'),
                severity: "error",
            }));
        }
    }

    const deleteItem = (id) => {
        dispatch(openModel({
            open: true,
            type: "DELETE",
            onClickSave: () => DeleteRoomStandard(id)
        }));
    }


    if (isroomStandardTableLoading) return <Loading />;
    return (
        <Box className="table-container">
            <Box className='table-grid room-standards-table'>
                <Box className='table-header-grid'>
                    <Grid container className='table-row-header-container'>
                        <Grid item className='cell-width-200'>Room Standard</Grid>
                        <Grid item className='cell-width-64'>Order</Grid>
                        <Grid item className='cell-width-100'>No of Unit</Grid>
                        <Grid item className='cell-width-64'>MON</Grid>
                        <Grid item className='cell-width-64'>TUE</Grid>
                        <Grid item className='cell-width-64'>WED</Grid>
                        <Grid item className='cell-width-64'>THU</Grid>
                        <Grid item className='cell-width-64'>FRI</Grid>
                        <Grid item className='cell-width-64'>SAT</Grid>
                        <Grid item className='cell-width-64'>SUN</Grid>
                        <Grid item className='cell-width-64'>L2</Grid>
                        <Grid item className='cell-width-64'>L3</Grid>
                        {allModulePermissions && allModulePermissions[Modules.RoomTypes].delete && <Grid item className='cell-width-64 flex-align-right'></Grid>}
                    </Grid>
                </Box>
                <DragDropContext onDragEnd={onEnd}>
                    <Droppable
                        droppableId="drag-drop-container"
                    >
                        {(provided, snapshot) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='table-body-grid'
                            >
                                {roomStandard.map((item, index) => (
                                    <Draggable
                                        draggableId={item.id.toString()}
                                        key={index}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <Box className='table-row-box active-table-grid'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Grid container className='table-row-data-container'>
                                                    <Grid item className='cell-width-200'><Box className='drag-icon-box' component='span' {...provided.dragHandleProps}><DragSvg /></Box><span className='table-bold-label'>{item.procedureTaskName}</span></Grid>
                                                    <Grid item className='cell-width-64'><span className='table-bold-label'>{item.order}</span></Grid>
                                                    <Grid item className='cell-width-100'>
                                                        <Box component='div' className='qty-box'>
                                                            <Box component='div' className={`qty-decrement`} onClick={(e) => item.noOfUnits > 0 && updateState(index, { ...item, noOfUnits: item.noOfUnits - 1 })}>-</Box>
                                                            <Box component='div' className='qty-number'>{item.noOfUnits}</Box>
                                                            <Box component='div' className='qty-increment' onClick={(e) => updateState(index, { ...item, noOfUnits: item.noOfUnits + 1 })}>+</Box>
                                                        </Box>
                                                    </Grid>
                                                    {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "level2", "level3"].map((val, i) =>

                                                        <Grid key={i} item className='cell-width-64'><AppCheckbox
                                                            handleChange={(e) => updateState(index, { ...item, [val]: e.target.checked })}
                                                            check={item[val] ?? false} /></Grid>
                                                    )}
                                                    {allModulePermissions && allModulePermissions[Modules.RoomTypes].delete && <Grid item className='cell-width-64 flex-align-right'>
                                                        <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => deleteItem(item.id)} icon={faTrashCan} className='trash-icon' />
                                                    </Grid>}
                                                </Grid>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
                <Box sx={{ display: "flex" }} py={3}>
                    <Box component="div" className="title-info-room-standard">
                        <FontAwesomeIcon icon={faCircleInfo} className="form-info-icon" />
                        <Typography className="form-info-icon" >  L2 -</Typography>
                        <Typography>
                            Level 2 cleaning @75% labour hours available
                        </Typography>
                    </Box>

                    <Box component="div" className="title-info-room-standard" mx={3}>
                        <FontAwesomeIcon icon={faCircleInfo} className="form-info-icon" />
                        <Typography className="form-info-icon" >  L3 -</Typography>
                        <Typography>
                            Level 3 cleaning @50% labour hours available
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default TableComponent;


export function DragSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
    );
}