import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box, Checkbox, Grid } from "@mui/material";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import * as yup from "yup";
import { GetMethod, PostMethod } from "Api/Methods/apiMethods";
import { EndPoints } from "Api/Endpoints/endPoints";
import AppButton from "../../../components/ui/buttons/AppButton";
import SelectField from "components/form/SelectField";
import stateJson from "./state.json";
import { FieldArray, Formik, useField, useFormikContext } from "formik";
import InputField from "components/form/InputField";
import FormDatePicker from "components/form/FormDatePicker";
import RadioButton from "components/form/RadioButton";
import { userRole } from "./AddNew";
import { useSelector } from "react-redux";
import { userSelector } from "Redux/User/UserSlice";
import { useDispatch } from "react-redux";
import { setSelectedUserRole } from "Redux/User/UserSlice";
import { t } from "i18next";
import { SpecialCharacters } from "components/form/FormInput";

yup.addMethod(yup.object, "unique", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }
    if (
      this.parent
        .filter((v) => v !== value)
        .some((v) => {
          if (v[propertyName] && value[propertyName]) {
            return v[propertyName] === value[propertyName];
          }
          return false;
        })
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }
    return true;
  });
});

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please fill the First Name"),
  lastName: yup.string().required("Please fill the Last Name"),
  supervisorId: yup.number().required("Please select a supervisor"),
  shiftId: yup.number().required("Please select a shift Id"),
  // roleId: yup.number().required("Please select a role Id"),
  state: yup.string().required("Please Select a state"),
  // /^(?:0*(?:\.\d+)?|1(\.0*)?)$/
  productivityRatio: yup.number().lessThan(1.01, "Please enter in between 0.00 to 1.00").required("Please fill the Productivity ratio"),
  hoursScheduled: yup
    .number()
    .min(1, "Values should not be less then 1 hour")
    .max(24, "Values should not be greater then 24 hours"),
  daysPerWeek: yup
    .number()
    .min(1, "Values should not be less then 1 day")
    .max(7, "Values should not be greater then 7 days"),
  // dateOfHire: yup.number().required("Please select a date"),
  schedules: yup.array().of(
    yup.object().shape({
      scheduleId: yup.number().required("Please select a schedule"),
      isPrimary: yup.boolean(),
      week1: yup.object().shape({
        monday: yup.boolean(),
        tuesday: yup.boolean(),
        wednesday: yup.boolean(),
        thursday: yup.boolean(),
        friday: yup.boolean(),
        saturday: yup.boolean(),
        sunday: yup.boolean(),
      }),
      week2: yup.object().shape({
        monday: yup.boolean(),
        tuesday: yup.boolean(),
        wednesday: yup.boolean(),
        thursday: yup.boolean(),
        friday: yup.boolean(),
        saturday: yup.boolean(),
        sunday: yup.boolean(),
      }),
    }).unique("scheduleId", "Duplicate Schedule"),
  )
});
const initialSchedule = {
  scheduleId: "",
  isPrimary: 0,
  week1: {
    weekId: 1,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  week2: {
    weekId: 2,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
};

const initialValues = {
  firstName: "",
  middleInitial: "",
  lastName: "",
  supervisorId: "",
  shiftId: "",
  roleId: null,
  state: "",
  productivityRatio: 1,
  hoursScheduled: 0,
  daysPerWeek: 0,
  dateOfHire: new Date(),
  schedules: [initialSchedule],
};

function AddNewEmployee(props, ref) {
  const { selectedUserRole, edit, editData } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState({
    supervisor: [],
    shift: [],
    schedules: [],
  });

  useEffect(() => {
    Promise.all([
      GetMethod(EndPoints.getAllsupervisorUsers),
      GetMethod(EndPoints.getAllShiftsByCampus),
      GetMethod(EndPoints.getSchedule),
    ])
      .then((res) => {
        let [supervisor, shift, schedules] = res;
        setDropdown({
          supervisor: supervisor.data.data,
          shift: shift.data.data,
          schedules: schedules.data.data,
        });
      })
      .catch((err) => {
      });
  }, []);

  const formicRef = useRef({ submit: () => { } });

  useImperativeHandle(ref, () => ({
    submit: handleSubmitAction,
  }));

  const handleSubmitAction = async () => {
    const errors = await formicRef.current.validateForm();
    if (Object.keys(errors).length || isPrimaryError) {
      formicRef.current.handleSubmit();
      return { isValid: false, response: formicRef.current.values };
    }
    // if(!selectedUserRole) dispatch(setSelectedUserRoleErrorTrue());
    return { isValid: true, response: formicRef.current.values };
  };

  const [isPrimaryError, setIsPrimaryError] = useState("");
  const isPrimaryChanged = async (checked, scheduleId, employeeId) => {
    if (checked) {
      try {
        let res = await PostMethod(EndPoints.checkprimary, JSON.stringify({ "scheduleId": scheduleId, "employeeId": employeeId }));
        if (res.data.errorCode === t('Users.Error-Code')) {
          setIsPrimaryError(t('Users.Schedule-Error'));
        }
        else {
          setIsPrimaryError("");
        }
      }
      catch (error) { }
    }
    else {
      setIsPrimaryError("");
    }
  };
  return (
    <Formik
      initialValues={edit ? editData : initialValues}
      innerRef={formicRef}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Grid
          container
          className="add-new-users modal-form-grid add-new-employee"
          direction={"row"}
          alignItems={"start"}
          rowSpacing={1}
          columnSpacing={{ xs: 0, sm: 2, md: 5 }}
          onSubmit={(values) => {}}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            rowSpacing={1}
            columnSpacing={3}
          >
            <Grid item xs={12} className="modal-form-cell">
              <Box component="div" className="user-role-section">
                <RadioButton
                  name={"roleId"}
                  label="User Role"
                  data={
                    edit
                      ? userRole().filter(
                          (item) => selectedUserRole === item.value
                        )
                      : userRole()
                  }
                  // error={selectedUserRoleError && "Please select a user role"}
                  // touched={selectedUserRoleError}
                  onChange={(e) =>
                    dispatch(setSelectedUserRole(parseInt(e.target.value)))
                  }
                  value={selectedUserRole}
                />
              </Box>
            </Grid>

            <Grid item xs={6} className="modal-form-cell">
              <InputField
                onChange={(e) => {
                  formik.setFieldValue(
                    "firstName",
                    SpecialCharacters(e.target.value)
                  );
                }}
                label="First Name"
                name="firstName"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <InputField
                onChange={(e) => {
                  formik.setFieldValue(
                    "middleInitial",
                    SpecialCharacters(e.target.value)
                  );
                }}
                label="Middle Name"
                name="middleInitial"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <InputField
                onChange={(e) => {
                  formik.setFieldValue(
                    "lastName",
                    SpecialCharacters(e.target.value)
                  );
                }}
                label="Last Name"
                name="lastName"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <SelectField
                label="State"
                name="state"
                options={stateJson.map((i) => ({
                  id: i.postal,
                  name: i.state,
                }))}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className="modal-form-cell"
              container
              alignItems="flex-end"
            >
              <SelectField
                label="Supervisor User"
                name="supervisorId"
                options={dropdown.supervisor}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className="modal-form-cell"
              container
              alignItems="flex-end"
            >
              <SelectField
                label="Shift"
                name="shiftId"
                options={dropdown.shift}
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <InputField
                label="Productivity Ratio"
                name="productivityRatio"
                InputProps={{
                  inputProps: { min: 0, max: 1, step: 0.01, precision: 2 },
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <InputField
                name="hoursScheduled"
                label="Hours Scheduled"
                InputProps={{ inputProps: { min: 1, max: 24 } }}
                type="number"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <InputField
                name="daysPerWeek"
                label="Days Per Week"
                InputProps={{ inputProps: { min: 1, max: 7 } }}
                type="number"
              />
            </Grid>
            <Grid item xs={6} className="modal-form-cell">
              <FormDatePicker label="Date of Hire" name="dateOfHire" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} container>
            <FieldArray name="schedules">
              {({ push, remove, form }) => {
                const { values } = form;
                return (
                  <Grid item xs={12} sm={12} md={12} container>
                    <Grid item xs={12}>
                      <Box component="div" className="touch-suface-box-title">
                        Schedules to assign this employee
                        <AppButton
                          buttonName="Add New"
                          variantType="blueOutline"
                          handleOnClick={() => push(initialSchedule)}
                        />
                      </Box>
                    </Grid>
                    {values.schedules.map((member, schIdx) => {
                      return (
                        <Grid item xs={12} key={schIdx} className="user-weeks">
                          <Grid container className="user-weeks-header">
                            <Grid item xs={5}>
                              <SelectField
                                label="Schedule"
                                name={`schedules[${schIdx}].scheduleId`}
                                options={dropdown.schedules}
                                callBackHandleChange={(e) => {
                                  isPrimaryChanged(
                                    values.schedules[schIdx].isPrimary,
                                    e,
                                    values.id
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} className="checkbox-label-grid">
                              <Box
                                className="checkbox-label-container"
                                component="div"
                              >
                                <FormCheckbox
                                  name={`schedules[${schIdx}].isPrimary`}
                                  scheduleIndex={schIdx}
                                  // scheduleId={values.schedules[index].scheduleId}
                                  handleChange={(e) =>
                                    isPrimaryChanged(
                                      e.target.checked,
                                      values.schedules[schIdx].scheduleId,
                                      values.id
                                    )
                                  }
                                />
                                <Box
                                  component="div"
                                  className="reinspection-label"
                                >
                                  Primary reponsibility
                                </Box>
                                {isPrimaryError && (
                                  <p style={{ color: "red" }}>
                                    {isPrimaryError}
                                  </p>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              container
                              alignItems="flex-end"
                              justifyContent="flex-end"
                              xs={1}
                            >
                              <FontAwesomeIcon
                                onClick={() => remove(schIdx)}
                                icon={faTrashCan}
                                className="small-delete-icon-button"
                              />
                            </Grid>
                          </Grid>

                          <Grid container className="week-head">
                            <Grid item className="week-30"></Grid>
                            {[
                              "MON",
                              "TUE",
                              "WED",
                              "THU",
                              "FRI",
                              "SAT",
                              "SUN",
                            ].map((item, index) => (
                              <Grid key={index} item className="week-10">
                                {item}
                              </Grid>
                            ))}
                          </Grid>
                          {["week1", "week2"].map((week, i) => (
                            <Grid key={i} container className="week-rows">
                              <Grid item className="week-30">
                                {week}
                              </Grid>
                              {[
                                "monday",
                                "tuesday",
                                "wednesday",
                                "thursday",
                                "friday",
                                "saturday",
                                "sunday",
                              ].map((item, index) => (
                                <Grid key={index} item className="week-10">
                                  <FormCheckbox
                                    scheduleIndex={schIdx}
                                    scheduleId={`schedules[${schIdx}].scheduleId`}
                                    name={`schedules[${schIdx}].${week.toLowerCase()}.${item}`}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ))}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              }}
            </FieldArray>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default forwardRef(AddNewEmployee);

export const FormCheckbox = ({
  name,
  scheduleIndex,
  handleChange = null,
}) => {
  const { values, validateField, setFieldTouched } = useFormikContext();
  const [field, meta, helper] = useField(name);

  const configField = {
    ...field,
    checked: field.value,
    onChange: (e) => {
      if (!values.schedules[scheduleIndex]?.scheduleId) {
        // validateField(`schedules[${scheduleIndex}]?.scheduleId`);
        setFieldTouched(`schedules[${scheduleIndex}]?.scheduleId`);
      } else {
        helper.setValue(e.target.checked);
      }
      if(handleChange) handleChange(e);
    },
  };
  return <Checkbox className="app-checkbox" id={name} {...configField} />;
};
