import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";

export const GetAllRoom = createAsyncThunk(
  "roomStandards/getallroomtype",
  async (_, { thunkAPI }) => {
    try {
      let res = await GetMethod(EndPoints.getAllAvailableRoomType);
      let roomStandard = [];
      let selectedRoomTypeId = res.data?.data[0]?.id ?? null;
      if (res.status === 200)
        roomStandard = await GetMethod(
          EndPoints.allRoomTypeTaskByRoomId(selectedRoomTypeId)
        );
      return {
        selectedRoomTypeId,
        roomtypes: res.data.data,
        roomStandard: roomStandard?.data?.data?.sort((a, b) => (a.order - b.order)) ?? [],
      };
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

export const GetRoomStandards = createAsyncThunk(
  "roomStandards/getroomstandards",
  async ({ id }, { thunkAPI }) => {
    try {
      let res = await GetMethod(EndPoints.allRoomTypeTaskByRoomId(id));
      return res?.data?.data?.sort((a, b) => (a.order - b.order)) ?? [];
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

export const GetProcedureTask = createAsyncThunk(
  "roomStandards/getproceduretask",
  async (_, { thunkAPI }) => {
    try {
      let res = await GetMethod(EndPoints.getAllAvailableTask);
      return res.data.data ?? [];
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

const initialState = {
  RoomTypes: [],
  selectedRoomTypeId: null,
  roomStandard: [],
  isroomStandardTableLoading: false,
  isGetAllLoading: true,
  AddnewId: null,
  // add new item
  openModel: false,
  newEditObject: {},
  procedureTask: [],
  addNewError: {},

  // bulk update
  updateObject: {}
};

export const roomStandardsSlice = createSlice({
  name: "roomStandards",
  initialState,
  reducers: {
    setSelectedRoomTypeId: (state, { payload }) => {
      state.selectedRoomTypeId = payload.id;
    },
    setAddNewError: (state, { payload }) => {
      state.addNewError = payload.data;
    },
    setOpenModel: (state, { payload }) => {
      state.addNewError = {};
      state.openModel = payload.open;
    },
    setCloseModel: (state, { payload }) => {
      state.addNewError = {};
      state.newEditObject = {};
      state.openModel = payload.open;
    },
    setNewEditObject: (state, { payload }) => {
      state.newEditObject = {
        ...state.newEditObject,
        [payload.key]: payload.value
      };
    },
    UpdateRoomStandard: (state, { payload }) => {
      state.roomStandard[payload.index] = payload.updatedData;
    },
    UpdateAllRoomStandard: (state, { payload }) => {
      state.roomStandard = payload;
    },
    setAddnewId: (state, action) => {
      state.AddnewId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoom.fulfilled, (state, action) => {
        state.RoomTypes = action.payload.roomtypes;
        state.selectedRoomTypeId = action.payload.selectedRoomTypeId;
        state.AddnewId = action.payload.selectedRoomTypeId;
        state.roomStandard = action.payload.roomStandard;
        state.isGetAllLoading = false;
      })
      .addCase(GetAllRoom.rejected, (state, action) => {
        state.isGetAllLoading = false;
      })
      .addCase(GetRoomStandards.pending, (state, { meta, payload }) => {
        const { id } = meta.arg;
        state.selectedRoomTypeId = id;
        state.roomStandard = [];
        state.isroomStandardTableLoading = true;
      })
      .addCase(GetRoomStandards.fulfilled, (state, { payload }) => {
        state.roomStandard = payload;
        state.isroomStandardTableLoading = false;

      })
      .addCase(GetRoomStandards.rejected, (state, { meta, payload }) => {
        state.roomStandard = [];
        state.isroomStandardTableLoading = false;
      })
      // Reducer for add or edit functionality
      .addCase(GetProcedureTask.fulfilled, (state, { payload }) => {
        state.procedureTask = payload;
      });
  },
});

export const roomStandardsSelector = (state) => state.roomStandards;

export const {
  setSelectedRoomTypeId,
  setOpenModel,
  setNewEditObject,
  setAddNewError,
  UpdateAllRoomStandard,
  UpdateRoomStandard,
  setAddnewId, setCloseModel } = roomStandardsSlice.actions;

export default roomStandardsSlice.reducer;
