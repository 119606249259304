import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "../../Api/Endpoints/endPoints";
import { GetMethod } from "../../Api/Methods/apiMethods";
import dayjs from 'dayjs';


let now = dayjs()
let initialDate = now.format("MM/DD/YYYY")

const initialState = {
    availableInspectionDates: [],
    isGetAllLoading: false,
    SelectedInspection: [],
    selectedDate: initialDate
}

export const GetAllavailableInspectionDates = createAsyncThunk('inspectiondates/getAllavailableInspectionDates', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAvailableInspectionDate)
        return res.data.data ?? []

    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})

export const InspectionDatesSlice = createSlice({
    name: "inspectiondates",
    initialState,
    reducers: {
        setSelectedInspection: (state, action) => {
            state.SelectedInspection = action.payload;
        },
        clearID: (state, action) => {
            state.SelectedInspection = []
            state.selectedDate = dayjs()
        },
        setDate: (state, action) => {
            state.selectedDate = action.payload
        },
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },

    },
    extraReducers(builder) {
        builder
            .addCase(GetAllavailableInspectionDates.fulfilled, (state, action) => {
                state.availableInspectionDates = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllavailableInspectionDates.pending, (state, action) => {
                state.isGetAllLoading = true
            })
            .addCase(GetAllavailableInspectionDates.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
    }


})

//Selectors
export const InspectionDateSelector = (state) => state.inspectiondates


//Actions
export const { setSelectedInspection, setChecked, setUnChecked, clearID, setDate, openModel, closeModel } = InspectionDatesSlice.actions;



//Reducer
export default InspectionDatesSlice.reducer;