import React from "react"
import { TextField, MenuItem } from "@mui/material"
import { useField } from "formik"
import Autocomplete from "@mui/material/Autocomplete";

const SelectField = ({
  name,
  options,
  label,
  labelVisible,
  callBackHandleChange,
  autoComplete = false,
  sx = {},
  ...otherProps
}) => {
  const [field, meta, helper] = useField(name);
  const optionId = !field.value ? options[0]?.id : field.value;

  const handleChange = (e, newValue) => {
    const { value } = e.target;
    autoComplete ? helper.setValue(newValue.id) : helper.setValue(value);
    if (callBackHandleChange) callBackHandleChange(value);
  };

  const configSelectField = {
    ...otherProps,
    select: true,
    ...field,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelectField.error = true;
    configSelectField.helperText = meta.error;
  }
  return (
    // <FormControl variant="standard" className="app-select">
    //     {labelVisible && <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>}
    //     <Select labelId="select-label-id" id="select-id" value={selectOption} label={label}>
    //         {/* <MenuItem value=""><em>None</em></MenuItem> */}
    //         {menuItems?.map((item, key) => (
    //             <MenuItem value={key}>{item.name}</MenuItem>
    //         ))}
    //     </Select>
    // </FormControl>
    <>
      {autoComplete ? (
        <Autocomplete
          disableClearable
          options={options}
          getOptionLabel={(option) => option.name}
          onChange={handleChange}
          value={options?.find((i) => i.id === optionId) ?? { id: 0, name: "" }}
          sx={sx}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="standard"
              className="form-input"
            />
          )}
        ></Autocomplete>
      ) : (
        <TextField
          {...configSelectField}
          className="app-select"
          variant="standard"
          label={label || ""}
        >
          {options?.map((item, key) => (
            <MenuItem key={key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default SelectField
