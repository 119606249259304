import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../assets/translations/en.json';
import es from '../assets/translations/es.json';

const resources = {
    en: {translation: en},
    es: {translation: es}
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: resources,
    fallbackLng: 'en',
    //debug: true,
    //   process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

  export default i18n