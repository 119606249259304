import React, { useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { numberFormat } from "utils/helpers";

const InputField = ({
  initialValue,
  label,
  placeholderLabel,
  onInputChange,
  name,
  value,
  error,
  helperText,
  maxLength,
  step,
  endIcon,
  inputProps = {},
  InputProps = {},
  readOnly,
  type,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const {
    values: { minPerSf },
    touched,
    setFieldValue,
  } = useFormikContext();

  const configInputField = {
    ...field,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    configInputField.error = true;
    configInputField.helperText = meta.error;
  }

  useEffect(() => {
    setFieldValue("minPer1000Sf", numberFormat(1000 * minPerSf));
    setFieldValue(
      "sfPerHour",
      isFinite(60 / minPerSf) ? numberFormat(Number(60 / minPerSf)) : 0
    );
  }, [minPerSf, touched.minPerSf, setFieldValue]);

  return (
    <TextField
      error={error}
      helperText={helperText}
      placeholder={placeholderLabel}
      onChange={onInputChange}
      label={label ?? ""}
      name={name}
      id={name}
      variant="standard"
      value={value}
      className="form-input"
      inputProps={{ maxLength: maxLength, step: step, readOnly: readOnly }}
      type={type || "text"}
      InputProps={{
        ...InputProps,
        endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
      }}
      {...configInputField}
    />
  );
};

export default InputField;
