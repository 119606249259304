import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod, PostMethod } from "../../Api/Methods/apiMethods";

export const GetAllReportTypes = createAsyncThunk(
    "ViewReports/GetAllReportTypes",
    async (_, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getReportType)
            return res.data.data ?? {}

        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const GetAllViewReports = createAsyncThunk(
    "ViewReports/GetAllViewReports",
    async (_, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getALLViewReports)
            return res.data.data ?? {}

        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const GetQueryParams = createAsyncThunk(
  "ViewReports/GetQueryParams",
  async (reportId, thunkAPI) => {
    try {
      const res = await GetMethod(EndPoints.getQueryParams(reportId));
      return res.data.data ?? {};
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const GetViewReportDetails = createAsyncThunk(
  "ViewReports/GetViewReportDetails",
  async (reportParams, thunkAPI) => {
    try {
      const { data: reportDetail } = await GetMethod(
        EndPoints.getReportDetails(reportParams.reportId)
      );
      const { data: columnData } = await GetMethod(
        EndPoints.getQueryColumns(reportParams.queryId)
      );
      let { data: reportData } = await PostMethod(EndPoints.fetchReportData, {
        reportId: reportParams.reportId,
        queryParams: reportParams.queryParams,
      });
      if (
        reportDetail.isSuccess &&
        columnData.isSuccess &&
        reportData.isSuccess
      )
        return {
          reportDetail: reportDetail.data,
          columnData: columnData.data,
          reportData: JSON.parse(reportData.data),
        };
      else return thunkAPI.rejectWithValue("error");
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
    ViewReports: [],
    reportTypes: [],
    reportCategories: [],
    open: null,
    isLoading: false,
    currentReport: '',
    searchText: '',
    reportId: 0,
    reportDetails: {},
    reportData: [],
    queryParams: [],
    selectedOptions: [],
  isLoadingPreview: true,
    modalLoader: false,
}

export const ViewReportSlice = createSlice({
  name: "ViewReports",
  initialState,
  reducers: {
    openModel: (state, action) => {
      state.open = true;
    },
    closeModel: (state, action) => {
      state.open = false;
    },
    setCurrentReport: (state, { payload }) => {
      state.currentReport = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    SortViewReports: (state, { payload }) => {
      state.ViewReports = payload;
    },
    updateQueryParams: (state, { payload: { name, value } }) => {
      const updatedQueryParams = state.queryParams
        .map((param) => {
          return param.paramName === name ? { ...param, value } : param;
        });
      state.queryParams = updatedQueryParams;
    },
    updateErrorFlag: (state, { payload }) => {
      state.queryParams = payload;
    },
    updateDate: (state, { payload }) => {
      state.queryParams = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllReportTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllReportTypes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.reportTypes = payload;
      })
      .addCase(GetAllReportTypes.rejected, (state) => {
        state.isLoading = false;
        state.reportTypes = [];
      })
      .addCase(GetAllViewReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllViewReports.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.ViewReports = payload || [];
      })
      .addCase(GetAllViewReports.rejected, (state) => {
        state.isLoading = false;
        state.ViewReports = [];
      })
      .addCase(GetQueryParams.fulfilled, (state, { payload }) => {
        state.isLoadingPreview = false;
        state.reportId = payload.reportId;
        state.queryId = payload.queryId;
        state.queryParams = payload.queryParams;
        state.modalLoader = false;
      })
      .addCase(GetQueryParams.pending, (state, { payload }) => {
        state.isLoadingPreview = true;
        state.modalLoader=true;
      })
      .addCase(GetQueryParams.rejected, (state) => {
        state.isLoadingPreview = false;
        state.modalLoader = false;
        state.queryParams = {};
      })
      .addCase(GetViewReportDetails.pending, (state) => {
        state.isLoadingPreview = true;
      })
      .addCase(GetViewReportDetails.fulfilled, (state, { payload }) => {
        state.isLoadingPreview = false;
        state.queryColumns = payload.columnData;
        state.reportDetails = payload.reportDetail;
        state.reportData = payload.reportData;
      })
      .addCase(GetViewReportDetails.rejected, (state) => {
        state.isLoadingPreview = false;
        state.queryColumns = {};
        state.reportDetails = {};
      });
  },
});


//Selectors
export const ViewReportsSelector = (state) => 
state.ViewReports;

//Actions
export const {
  setCurrentReport,
  setSearchText,
  updateQueryParams,
  updateErrorFlag,
  updateDate,
  SortViewReports,
} = ViewReportSlice.actions;

//Reducer
export default ViewReportSlice.reducer;