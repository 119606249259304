import React from "react";
import { useEffect, useState } from "react";
import "./OfflineCheck.scss";

export default function OfflineCheck() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  
  return isOnline === false ? <div className="offline">Oops! It seems like you're currently offline. Please check your internet connection.</div> : <></>;
}
