import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import './app-modal.scss';
import FormButton from 'components/form/FormButton';
import AppButton from 'components/ui/buttons/AppButton'

export default function AppModal({ iconTrue,
    buttonName,
    variantType,
    buttonIcon,
    modalTitle,
    dialogBody,
    submitButtonLabel,
    onModalSubmit,
    disable = false,
    handleClose,
    handleUpdateClose,
    handleClickOpen,
    Open,
    isDelete,
    isUpdate,
    isDownloadable=false,
    submitButtonVarientGreen = false,
    saveAndContinue = false,
    isModalButton,
    modalClassName = ""
}) {

    return (
      <>
        {isDelete || isUpdate || isModalButton ? null : (
          <AppButton
            handleOnClick={handleClickOpen}
            iconTrue={iconTrue}
            buttonName={buttonName}
            variantType={variantType}
            buttonIcon={buttonIcon}
            disable={disable}
          />
        )}

        <Dialog
          open={Open}
          onClose={handleClose}
          className={`app-modal ${modalClassName}`}
        >
          <DialogTitle className="dialog-header">
            <span>{modalTitle}</span>
            <FontAwesomeIcon
              icon={faClose}
              className="modal-close-icon"
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent className="dialog-content">{dialogBody}</DialogContent>
                {!isDownloadable && (<DialogActions className="dialog-footer">
                    <AppButton
                        handleOnClick={isUpdate ? handleUpdateClose : handleClose}
                        buttonName={isUpdate ? "Continue without Saving" : "Cancel"}
                        variantType="greyOutline"
                    />
                    {saveAndContinue && (
                        <AppButton
                            handleOnClick={onModalSubmit}
                            buttonName="Save and Add another"
                            variantType={"greenButton"}
                        />
                    )}
                    {!onModalSubmit && (
                        <FormButton variant="greenButton">{submitButtonLabel}</FormButton>
                    )}
                    {onModalSubmit && (
                        <AppButton
                            handleOnClick={onModalSubmit}
                            buttonName={submitButtonLabel}
                            variantType={
                                submitButtonVarientGreen
                                    ? "greenButton"
                                    : isDelete
                                        ? "redButton"
                                        : "greenButton"
                            }
                            buttonIcon="plus"
                        />
                    )}
                </DialogActions>)}
        </Dialog>
      </>
    );
}
