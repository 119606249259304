import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints';
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllShiftTimes = createAsyncThunk("shifttimes/getallshifttimes",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllShiftTimes)
            return res.data

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    })



const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    ShiftTimes: [],
    UpdateError: false,
    UpdateShiftTimes: {

    },
    AMPM: true
}

export const shifttimesSlice = createSlice({
    name: "shifttimes",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditShiftTimes: (state, { payload }) => {
            state.ShiftTimes = { data: state.ShiftTimes.data.map((item => item.id === payload.data.id ? payload.data : item)) };
            state.UpdateShiftTimes[payload.data.id] = { id: payload.data.id, ...payload.data };
        },
        ClearUpdate: (state) => {
            state.UpdateShiftTimes = {}
        },
        SwitchAMPM: (state, action) => {
            state.AMPM = action.payload
        },
        ToggleUpdateError: (state, action) => {
            state.UpdateError = action.payload
        },

    },
    extraReducers(builder) {
        builder
            .addCase(GetAllShiftTimes.fulfilled, (state, action) => {
                state.ShiftTimes = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllShiftTimes.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
    }

});

//Selectors

export const ShiftTimesSelector = (state) => state.shifttimes


export const { openModel, closeModel, EditShiftTimes, ClearUpdate, SwitchAMPM, ToggleUpdateError } = shifttimesSlice.actions;


export default shifttimesSlice.reducer;
