import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints';
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllSchedule = createAsyncThunk("schedule/getallschedule",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllSchedule)
            return res.data

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    })



const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    Schedules: [],
    UpdateError: false,
    UpdateSchedule: {

    }
}

export const scheduleSlice = createSlice({
    name: "schedule",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        Editschedule: (state, { payload }) => {
            state.Schedules = { data: state.Schedules.data.map((item => item.id === payload.data.id ? payload.data : item)) };
            state.UpdateSchedule[payload.data.id] = { id: payload.data.id, ...payload.data };
        },
        ClearUpdate: (state) => {
            state.UpdateSchedule = {}
        },
        UpdateErrorfalse: (state, action) => {
            state.UpdateError = state.UpdateError = false
        },
        UpdateErrortrue: (state, action) => {
            state.UpdateError = state.UpdateError = true
        },
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllSchedule.fulfilled, (state, action) => {
                state.Schedules = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllSchedule.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
    }

});

//Selectors

export const ScheduleSelector = (state) => state.schedule


export const { openModel, closeModel, Editschedule, ClearUpdate, UpdateErrortrue, UpdateErrorfalse } = scheduleSlice.actions;


export default scheduleSlice.reducer;
