import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "../../Api/Endpoints/endPoints";
import { GetMethod } from "../../Api/Methods/apiMethods";

const initialState = {
    isLoading: false,
    open: false,
    isEdit: false,
    isGetAllLoading: true,
    Categories: [],
    UpdateCategories: {},
    CategoryDatabyId: {

    }

}

export const getAllCategories = createAsyncThunk('categories/getallcategories', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getCategoriesAll)
        return res.data

    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})

export const getCategoriesById = createAsyncThunk('categories/getcategoriesbyid', async (categoryId, thunkAPI) => {
    try {

        let res = await GetMethod(EndPoints.getCategorybyId(categoryId))
        return res.data.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})


const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    openModel: (state) => {
      state.open = true;
    },
    closeModel: (state) => {
      state.open = false;
    },
    EditFalse: (state) => {
      state.isEdit = false;
      state.CategoryDatabyId = {};
    },
    EditACategory: (state, { payload }) => {
      state.Categories = {
        data: state.Categories.data.map((item) =>
          item.id === payload.data.id ? payload.data : item
        ),
      };
      state.UpdateCategories[payload.data.id] = {
        id: payload.data.id,
        isAvailable: payload.data.isAvailable,
      };
    },
    EditAllCategories: (state, { payload }) => {
      state.Categories = { data: payload.allWithUpdated };
      state.UpdateCategories = payload.updatedData.reduce((pre, curr) => {
        pre[curr.id] = { id: curr.id, isAvailable: curr.isAvailable };
        return pre;
      }, {});
    },
    ClearUpdate: (state) => {
      // state.Categories = [];
      state.UpdateCategories = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.Categories = action.payload;
        state.isGetAllLoading = false;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isGetAllLoading = false;
      })
      .addCase(getCategoriesById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.CategoryDatabyId = action.payload;
      })
      .addCase(getCategoriesById.pending, (state, action) => {
        state.isLoading = true;
        state.isEdit = true;
        state.open = true;
      })
      .addCase(getCategoriesById.rejected, (state, action) => {
        state.isLoading = true;
        state.isEdit = false;
        state.open = false;
        state.CategoryDatabyId = {};
      });
  },
});

//Selectors

export const categorySelector = (state) => state.categories;

export const AllCategories = (state) => state.categories.Categories;
export const isCategoriesLoading = (state) => state.categories.isLoading
export const Open = (state) => state.categories.open
export const CategoryById = (state) => state.categories.CategoryDatabyId
export const isEdit = (state) => state.categories.isEdit

export const isLoading = (state) => state.categories.isLoading;


//Actions
export const {
  openModel,
  closeModel,
  EditFalse,
  EditACategory,
  ClearUpdate,
  EditAllCategories,
} = categoriesSlice.actions;



//Reducer
export default categoriesSlice.reducer;