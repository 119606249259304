import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from 'react-redux'

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllFlooringType = createAsyncThunk("flooringtype/getallflooringtype",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllFlooringTypes)
            return res.data
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue({ error: error });
        }
    })

export const GetFlooringTypeById = createAsyncThunk(
    "flooringtype/getFlooringTypeById",
    async (id, thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getFlooringTypeById(id));
            return response.data.data

        } catch (error) {
            console.log('Error while fetch Get Flooring Type By Id', error);
            return thunkAPI.rejectWithValue({ error: error });

        }
    }
);

const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    FlooringTypes: [],
    UpdateFlooringTypes: {},
    FlooringTypeData: {}
}

export const flooringtypeSlice = createSlice({
    name: "flooringtype",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditFalse: (state) => {
            state.isEdit = false
            state.FlooringTypeData = {}
        },
        EditFlooringType: (state, { payload }) => {
            state.FlooringTypes = { data: state.FlooringTypes.data.map((item => item.id === payload.data.id ? payload.data : item)) }
            state.UpdateFlooringTypes[payload.data.id] = { id: payload.data.id, isAvailable: payload.data.isAvailable };
        },
        EditAllFlooringType: (state, { payload }) => {
            state.FlooringTypes = { data: payload.allWithUpdated };
            state.UpdateFlooringTypes = payload.updatedData.reduce((pre, curr) => {pre[curr.id]  = {id : curr.id, isAvailable : curr.isAvailable }; return pre;}, {});
        },
        ClearUpdate: (state) => {
            state.UpdateFlooringTypes = {}
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllFlooringType.fulfilled, (state, action) => {
                state.FlooringTypes = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllFlooringType.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetFlooringTypeById.fulfilled, (state, action) => {
                state.isLoading = false
                state.FlooringTypeData = action.payload
            })
            .addCase(GetFlooringTypeById.pending, (state, action) => {
                state.isEdit = true
                state.isLoading = true
                state.open = true
            })
            .addCase(GetFlooringTypeById.rejected, (state, action) => {
                state.isLoading = false
                state.FlooringTypeData = {}
            })
    }

});

//Selectors

export const FlooringTypeSelector = (state) => state.flooringtype

export const allFlooringType = (state) => state.flooringtype.FlooringTypes
export const isEdit = (state) => state.flooringtype.isEdit
export const FlooringtypebyID = (state) => state.flooringtype.FlooringTypeData
export const Open = (state) => state.flooringtype.open
export const Loading = (state) => state.flooringtype.isLoading


export const { openModel, closeModel, EditFalse, EditFlooringType, EditAllFlooringType, ClearUpdate } = flooringtypeSlice.actions;


export default flooringtypeSlice.reducer;
