import { BrowserRouter } from "react-router-dom";
import React, { useEffect } from "react";
import { GetMethod } from "Api/Methods/apiMethods";
import { EndPoints } from "Api/Endpoints/endPoints";
import OfflineCheck from "utils/OfflineCheck";
import LoginPage from "./pages/Login";

function App() {
    useEffect(() => {
    (async () => {
      let res = await GetMethod(EndPoints.getVersionDetails);
      localStorage.setItem("currentAppVersion", res?.data);
      localStorage.setItem("currentBrowserVersion", process.env.REACT_APP_VERSION);
      if (res?.data !== process.env.REACT_APP_VERSION) {
        window.location.reload();
      }
    })();
  }, []);
  return (
    <BrowserRouter basename="/">
      <OfflineCheck />
      <LoginPage />
    </BrowserRouter>

  );
}

export default App;
