import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";

export const getInitialData = createAsyncThunk(
  "RoomTypesInspection/getInitialData",
  async (_, thunkAPI) => {
    try {
      let [roomTypes, inspectionScale] = await Promise.all([
        GetMethod(EndPoints.getAllAvailableRoomType),
        GetMethod(EndPoints.getInspectionScale),
      ]);
      let InspectionItemByRoomType;
      if (roomTypes.data.isSuccess)
        InspectionItemByRoomType = await GetMethod(
          EndPoints.getInspectionItemByRoomType(roomTypes.data.data[0].id)
        );
      return {
        roomTypes: roomTypes.data.data,
        inspectionScale: inspectionScale.data.data,
        InspectionItemByRoomType: InspectionItemByRoomType.data.data?.sort((a, b) => (a.order - b.order)) ?? [],
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  }
);

export const GetInspectionItemByRoomType = createAsyncThunk("RoomTypesInspection/getInspectionItemByRoomType",
  async (_, thunkAPI) => {
    const { selectedRoomTypeID } = thunkAPI.getState().RoomTypesInspection;
    try {
      let { data } = await GetMethod(EndPoints.getInspectionItemByRoomType(selectedRoomTypeID));
      return data.data?.sort((a, b) => (a.order - b.order)) ?? [];
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  });

export const getAllInspectionCategoryItem = createAsyncThunk("RoomTypesInspection/getAllInspectionCategoryItem",
  async (_, thunkAPI) => {
    try {
      let { data } = await GetMethod(EndPoints.getAllAvailableInspectionCategoryItem);

      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  });

const initialState = {
  roomTypes: [],
  inspectionScale: [],
  selectedinspectionScaleID: null,
  selectedRoomTypeID: "",
  InspectionItemByRoomType: [],
  isLoading: false,
  isError: false,
  loadTable: false,

  InspectionCategoryItem: [],
  isAddNewModelLoading: false,

  isEdit: false,
  editData: {}
};

export const RoomTypesInspectionSlice = createSlice({
  name: "RoomTypesInspection",
  initialState,
  reducers: {
    setSelectedRoomTypeID: (state, { payload }) => {
      state.selectedRoomTypeID = payload;
    },
    setSelectedinspectionScaleID: (state, { payload }) => {
      state.selectedinspectionScaleID = payload;
    },
    setEdit: (state, { payload }) => {
      state.isEdit = true;
      state.editData = payload;
    },
    updateInspectionItemByRoomType: (state, { payload }) => {
      state.InspectionItemByRoomType = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getInitialData.fulfilled, (state, action) => {
        state.roomTypes = action.payload.roomTypes;
        state.inspectionScale = action.payload.inspectionScale;
        state.selectedRoomTypeID = action.payload.roomTypes[0].id;
        state.selectedinspectionScaleID = action.payload.inspectionScale[0].id;
        state.InspectionItemByRoomType = action.payload.InspectionItemByRoomType;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(getInitialData.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(getInitialData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllInspectionCategoryItem.fulfilled, (state, action) => {
        state.InspectionCategoryItem = action.payload;
        state.isAddNewModelLoading = false;
        state.isError = false;
      })
      .addCase(getAllInspectionCategoryItem.rejected, (state, action) => {
        state.isError = true;
        state.isAddNewModelLoading = false;
      })
      .addCase(getAllInspectionCategoryItem.pending, (state) => {
        state.isAddNewModelLoading = true;
      })
      .addCase(GetInspectionItemByRoomType.fulfilled, (state, action) => {
        state.InspectionItemByRoomType = action.payload;
        state.loadTable = false;
        state.isError = false;
      })
      .addCase(GetInspectionItemByRoomType.rejected, (state, action) => {
        state.isError = true;
        state.loadTable = false;
      })
      .addCase(GetInspectionItemByRoomType.pending, (state) => {
        state.loadTable = true;
      });
  },
});

export const RoomTypesInspectionSelector = (state) => state.RoomTypesInspection;

export const { setSelectedRoomTypeID, setSelectedinspectionScaleID, updateInspectionItemByRoomType, setEdit } =
  RoomTypesInspectionSlice.actions;

export default RoomTypesInspectionSlice.reducer;
