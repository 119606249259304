import React from "react"
import "./form.scss"
import Checkbox from "@mui/material/Checkbox"
import { useField } from "formik"

const AppCheckbox = ({ handleChange, check, value, index, defaultchecked, name, disabled }) => {
    return <Checkbox checked={check} value={value} onChange={handleChange} className="app-checkbox" name={name} disabled={disabled} />
}

export default AppCheckbox


export const FormCheckbox = ({ name }) => {
    const [field, , helper] = useField(name);
   
    const configField = {
        ...field,
       checked : field.value,
        onChange: (e) => {
            helper.setValue(e.target.checked)
        },
    }
    return <Checkbox className="app-checkbox" id={name} {...configField} />
}