import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { EndPoints } from "../../Api/Endpoints/endPoints"
import { GetMethod } from "../../Api/Methods/apiMethods"



export const getSelectedCategories = createAsyncThunk("inspectioncategoryitem/selectedCategories", async (_,thunkAPI) => {
    try {
        let response = await GetMethod(EndPoints.getSelectedInspectionCategories);
        let { data } = await GetMethod(EndPoints.getInspectionCategoryItemByCategoryId(response.data.data[0].id));
        return {
            InspectionCategories :  response.data.data,
            InspectionCategoryItemByCategoryId : data.data
        };
    } catch (error) {
        console.log('Error while InspectionCategory Item Get By Id', error);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getInspectionCategoryItemByCategoryId = createAsyncThunk("inspectioncategoryitem/getInspectionItemByCategoryId", async (id, thunkAPI) => {
    try {
        let response = await GetMethod(EndPoints.getInspectionCategoryItemByCategoryId(id))
        return response.data.data

    } catch (error) {
        console.log('Error while InspectionCategory Item Get By Id', error);
        return thunkAPI.rejectWithValue(error);
    }
})


const initialState = {
    isLoading: false,
    isGetAllLoading: false,

    selectedCategories: [],
    currentCategories: {},  

    inspectionCategoryitems: [],

    isEdit : false,
    editValue : {},

    error : false
}

const inspectionCategoryItemSlice = createSlice({
  name: "inspectioncategoryitem",
  initialState,
  reducers: {
    setCurrentCategories: (state, action) => {
      state.currentCategories = action.payload;
    },
    EditCategory: (state, { payload }) => {
      state.inspectionCategoryitems = payload.allWithUpdated;
    },
    setEdit: (state, { payload }) => {
      state.isEdit = true;
      state.editValue = payload;
    },
    removeEdit: (state) => {
      state.isEdit = false;
      state.editValue = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSelectedCategories.pending, (state) => {
      state.isGetAllLoading = true;
      state.error = false;
    });
    builder.addCase(getSelectedCategories.fulfilled, (state, action) => {
      state.isGetAllLoading = false;
      state.selectedCategories = action.payload.InspectionCategories;
      state.currentCategories = action.payload.InspectionCategories[0];
      state.inspectionCategoryitems =
        action.payload.InspectionCategoryItemByCategoryId ?? [];
      state.error = false;
    });
    builder.addCase(getSelectedCategories.rejected, (state, action) => {
      state.isGetAllLoading = false;
      state.selectedCategories = [];
      state.error = true;
    });

    builder.addCase(getInspectionCategoryItemByCategoryId.pending, (state) => {
      state.isGetAllLoading = true;
      state.error = false;
    });
    builder.addCase(
      getInspectionCategoryItemByCategoryId.fulfilled,
      (state, action) => {
        state.isGetAllLoading = false;
        state.inspectionCategoryitems = action.payload || [];
        state.error = false;
      }
    );
    builder.addCase(
      getInspectionCategoryItemByCategoryId.rejected,
      (state, action) => {
        state.isGetAllLoading = false;
        state.error = action.payload;
        state.inspectionCategoryitems = [];
        state.error = true;
      }
    );
  },
});

export const InspectionCategoryItemSelector = (state) =>
  state.inspectioncategoryitem;

export const {
  setCurrentCategories,
  EditACategory,
  setEdit,
  removeEdit,
  EditCategory,
} = inspectionCategoryItemSlice.actions;
export default inspectionCategoryItemSlice.reducer
