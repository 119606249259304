import React from 'react';
import './app-button.scss';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faImage, faRightLong, faLeftLong, faCheck, faAngleDown, faAngleUp, faFloppyDisk, faRepeat, faRotateRight, faPrint, faStar, faFileLines, faCalendarDays, faTrashCan, faEnvelope, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

function AppButton({ iconTrue, iconPosEnd, buttonName, variantType, disable, buttonIcon, handleOnClick }) {

  if (buttonIcon === 'plus') {
    buttonIcon = faPlus;
  }

  if (buttonIcon === 'rightArrow') {
    buttonIcon = faRightLong;
  }

  if (buttonIcon === 'leftArrow') {
    buttonIcon = faLeftLong;
  }

  if (buttonIcon === 'upArrow') {
    buttonIcon = faAngleUp;
  }

  if (buttonIcon === 'tick') {
    buttonIcon = faCheck;
  }

  if (buttonIcon === 'downArrow') {
    buttonIcon = faAngleDown;
  }

  if (buttonIcon === 'save') {
    buttonIcon = faFloppyDisk;
  }

  if (buttonIcon === 'faRepeat') {
    buttonIcon = faRepeat;
  }

  if (buttonIcon === 'reload') {
    buttonIcon = faRotateRight;
  }

  if (buttonIcon === 'print') {
    buttonIcon = faPrint;
  }
  if (buttonIcon === 'star') {
    buttonIcon = faStar;
  }
  if (buttonIcon === 'document') {
    buttonIcon = faFileLines;
  }
  if (buttonIcon === 'calendar') {
    buttonIcon = faCalendarDays;
  }
  if (buttonIcon === 'trash') {
    buttonIcon = faTrashCan;
  }
  if (buttonIcon === 'image') {
    buttonIcon = faImage;
  }
  if (buttonIcon === 'mail') {
    buttonIcon = faEnvelope;
  }
  if (buttonIcon === 'search') {
    buttonIcon = faMagnifyingGlass;
  }
  
  const theme = createTheme({
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'blueButton', },
            style: {
              backgroundColor: '#2351C9',
              border: 'none',
              color: '#fff',
              '&:hover': {
                background: "#4b75e4",
              }
            },
          },
          {
            props: { variant: 'greenButton' },
            style: {
              backgroundColor: '#09BD3C',
              border: 'none',
              color: '#fff',
              '&:hover': {
                background: "#00972b",
              }
            },
          },
          {
            props: { variant: 'greyButton' },
            style: {
              backgroundColor: '#717579',
              border: 'none',
              borderRadius: '10px !important',
              color: '#fff',
              '&:hover': {
                background: "#8a8e92",
              }
            },
          },
          {
            props: { variant: 'greyOutline' },
            style: {
              // backgroundColor: '#fff',
              border: '#717579 1px solid',
              color: '#717579',
              '&:hover': {
                background: "#fff",
              }
            },
          },
          {
            props: { variant: 'blueOutline' },
            style: {
              backgroundColor: 'transparent',
              border: '#2351C9 1px solid',
              color: '#2351C9',
              '&:hover': {
                background: "transparent",
              }
            },
          },
          {
            props: { variant: 'blueLite' },
            style: {
              backgroundColor: 'rgba(35, 81, 201, 0.1)',
              color: '#2351C9',
              '&:hover': {
                background: "transparent",
              }
            },
          },
          {
            props: { variant: 'redButton' },
            style: {
              backgroundColor: '#E22B36',
              border: 'none',
              color: '#fff',
              '&:hover': {
                background: "#951c23",
              }
            },
          },
          {
            props: { variant: 'iconRoundGrey' },
            style: {
              backgroundColor: '#e8e9e7',
              border: 'none',
              borderRadius: '100vmax !important',
              color: '#6A7066',
              width: 36,
              height: 36,
              '&:hover': {
                background: "#c1c1c1",
              }
            },
          },
          {
            props: { variant: 'iconRoundGreen' },
            style: {
              backgroundColor: '#edf6e4',
              border: 'none',
              borderRadius: '100vmax !important',
              color: '#84C547',
              width: 36,
              height: 36,
              '&:hover': {
                background: "#d2ddc5",
              }
            },
          },
          {
            props: { variant: 'iconRoundBlue' },
            style: {
              backgroundColor: '#dee5f7',
              border: 'none',
              borderRadius: '100vmax !important',
              color: '#2351C9',
              width: 36,
              height: 36,
              '&:hover': {
                background: "#bac5dd",
              }
            },
          },
          {
            props: { variant: 'pinkButton' },
            style: {
              backgroundColor: '#FA626B',
              border: 'none',
              color: '#fff',
              '&:hover': {
                background: "#e84c57",
              }
            },
          },
        ],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button onClick={handleOnClick} className={iconPosEnd ? 'app-button icon-reverse' : 'app-button'} variant={variantType} disabled={disable ? true : null}>
        {(iconTrue && buttonIcon) && <FontAwesomeIcon icon={buttonIcon} /> }
        {buttonName && <Typography>{buttonName}</Typography>}
      </Button>
    </ThemeProvider>
  )
}

export default AppButton;