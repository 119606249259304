import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from 'react-redux'

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllRoomTypeApi = async () => {
    try {
        let res = await GetMethod(EndPoints.getAllRoomType)
        return res.data
    } catch (error) {
        return error;
    }
}

export const GetAllRoomType = createAsyncThunk("roomtype/getallroomtype", GetAllRoomTypeApi)

export const GetRoomTypeById = createAsyncThunk(
    "roomtype/getRoomTypeById",
    async (id, thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getRoomTypeById(id));
            return response.data.data
        } catch (error) {
            console.log('Error while fetch Get Room Type By Id', error);
            return thunkAPI.rejectWithValue({ error: error });

        }
    }
);

export const GetAvailableRoomType = createAsyncThunk(
    "roomtype/getAvailableroomType",
    async (thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getAllAvailableRoomType);
            return response.data.data
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });

        }
    }
);


const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    RoomTypes: [],
    AvailableRoomTypes: [],
    UpdateRoomTypes: {

    },

    RoomTypeData: {

    }

}

export const roomtypeSlice = createSlice({
    name: "roomtype",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditFalse: (state) => {
            state.isEdit = false
            state.RoomTypeData = {}
        },
        EditRoomType: (state, { payload }) => {
            state.RoomTypes = { data: state.RoomTypes.data.map((item => item.id === payload.data.id ? payload.data : item)) }
            state.UpdateRoomTypes[payload.data.id] = { id: payload.data.id, isAvailable: payload.data.isAvailable };
        },
        EditAllRoomType: (state, { payload }) => {
            state.RoomTypes = { data: payload.allWithUpdated };
            state.UpdateRoomTypes = payload.updatedData.reduce((pre, curr) => {pre[curr.id]  = {id : curr.id, isAvailable : curr.isAvailable }; return pre;}, {});
        },
        ClearUpdate: (state) => {
            state.UpdateRoomTypes = {}
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllRoomType.fulfilled, (state, action) => {
                state.RoomTypes = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllRoomType.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetRoomTypeById.fulfilled, (state, action) => {
                state.isLoading = false
                state.RoomTypeData = action.payload
            })
            .addCase(GetRoomTypeById.pending, (state, action) => {
                state.isEdit = true
                state.isLoading = true
                state.open = true
            })
            .addCase(GetRoomTypeById.rejected, (state, action) => {
                state.isLoading = false
                state.RoomTypeData = {}
            })
            .addCase(GetAvailableRoomType.fulfilled, (state, action) => {
                state.isLoading = false
                state.AvailableRoomTypes = action.payload
            })
    }

});

//Selectors

export const RoomTypeSelector = (state) => state.roomtype


export const { openModel, closeModel, EditFalse, EditRoomType, EditAllRoomType, ClearUpdate } = roomtypeSlice.actions;


export default roomtypeSlice.reducer;
