import React from 'react';
import { Box } from '@mui/material';
import './pre-loader.scss';

function PreLoader({pageLoader, elementLoader, loaderSize, whiteLoader}) {
    return (
        <Box component='div' className={pageLoader ? `loader-container page-loader ${whiteLoader}` : elementLoader ? `loader-container element-loader ${whiteLoader}` : `loader-container ${whiteLoader}`}>
            <Box component='div' className="loader"  style={{transform: `scale(${loaderSize})`}}>
                <Box component='div' className='logo-box'></Box>
                <Box component='span' className='white-dot'></Box>
                <Box component='span' className='blue-dot'></Box>
            </Box>
        </Box>
    )
}

export default PreLoader