import React from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './form-button.scss';

const FormButton = ({
    children,
    ...otherProps
}) => {

    const theme = createTheme({
        components: {
          MuiButton: {
            variants: [
              {
                props: { variant: 'greenButton' },
                style: {
                  backgroundColor: '#09BD3C',
                  border: 'none',
                  color: '#fff',
                  '&:hover': {
                    background: "#00972b",
                  }
                },
              },
            ],
          },
        },
      });

    const { submitForm } = useFormikContext();

    const handleSubmit = () => {
        submitForm();
    }

    const configButton = {
        ...otherProps,
        className: 'form-button',
        onClick: handleSubmit
    }

    return (
        <ThemeProvider theme={theme}>
            <Button {...configButton}>
                {children}
            </Button>
        </ThemeProvider>
    )
}

export default FormButton;