import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod, PostMethod } from "../../Api/Methods/apiMethods";

export const getAllRolePermissions = createAsyncThunk('rolePermissions/getAllRolePermissions', async (_, thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAllRolePermissions)
        return res.data.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
});

export const saveRolePermissions = createAsyncThunk('rolePermissions/saveRolePermissions', async (roleData, thunkAPI) => {
    try {
        let res = await PostMethod(EndPoints.saveRolePermissions, roleData)
        return JSON.parse(res.data.data);
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
});

export const getModulePermissions = createAsyncThunk('rolePermissions/getmodulepermissions', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getModulePermissions)
        return res.data.data
  
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
  })

const initialState = {
    isLoading: false,
    isGetAllLoading: true,
    admin: [],
    inspection: [],
    workLoading: [],
    updatedRolePermissions: [],
    allModulePermissions: null
}

export const rolePermissionsSlice = createSlice({
    name: "rolePermissions",
    initialState,
    reducers: {
        updateRolePermission: (state,action) => {
            const [section,entity,role,actionType] = action.payload?.split('-')
            const entityIndex = state[section].findIndex(el=>el.entityId===+entity) 
            const roleIndex = state[section][entityIndex].rolePermissionDetails.findIndex(el=>el.roleId===+role)

            state[section][entityIndex].rolePermissionDetails[roleIndex][actionType]=!state[section][entityIndex].rolePermissionDetails[roleIndex][actionType]
            const roleEntityIndex = state.updatedRolePermissions.findIndex(el=>el.entityId===+entity);
            if(roleEntityIndex > -1){
                const updatedRoleData = state.updatedRolePermissions[roleEntityIndex].rolePermissionDetails;
                const rolePermissionIndex = updatedRoleData.findIndex(el=>el.roleId===+role)
                if(rolePermissionIndex > -1){
                   const newPermissionEntry = updatedRoleData.map((el,index)=>index===rolePermissionIndex ? state[section][entityIndex].rolePermissionDetails[roleIndex] : el);
                   state.updatedRolePermissions[roleEntityIndex].rolePermissionDetails = newPermissionEntry
                }
                else{
                    state.updatedRolePermissions[roleEntityIndex].rolePermissionDetails.push(state[section][entityIndex].rolePermissionDetails[roleIndex])
                }
                
            }else{
                const updatedEntity = {entityId:state[section][entityIndex].entityId,entityName:state[section][entityIndex].entityName,rolePermissionDetails:[state[section][entityIndex].rolePermissionDetails[roleIndex]]}
                state.updatedRolePermissions.push(updatedEntity)
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getModulePermissions.fulfilled, (state, action) => {
                state.allModulePermissions = action.payload.reduce((pre, cur) => ({...pre, [cur.moduleId]: cur.permission}), {});
                state.isLoading = false;
            })
            .addCase(getModulePermissions.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getAllRolePermissions.fulfilled, (state, action) => {
                const {admin, inspection, workLoading} = action.payload;
                state.currentRolePermissions = action.payload;
                state.admin = admin
                state.inspection = inspection
                state.workLoading = workLoading
                state.isGetAllLoading = false
            })
            .addCase(getAllRolePermissions.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(getAllRolePermissions.pending, (state, action) => {
                state.isGetAllLoading = true
            })
            .addCase(saveRolePermissions.fulfilled, (state, action) => {
                state.updatedRolePermissions= [];
            })
    }

});

//Selectors

export const RolePermissionsSelector = (state) => state.rolePermissions


export const {updateRolePermission } = rolePermissionsSlice.actions;


export default rolePermissionsSlice.reducer;
