import { Fragment as Dummy_Div } from "react";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc');

dayjs.extend(utc)

export const numberFormat= (value) => Math.round(value * 100) / 100;

export const Fragment = Dummy_Div;

export let formatDate = (date, withTime = false) => {
    let time = dayjs.utc(date);
    if(!time.isValid()) return date;
    if(withTime) return time.local().format('MM/DD/YYYY hh:mm A');
    else return time.local().format('MM/DD/YYYY');
};

export let formatTime = (date) => {
    let time = dayjs.utc(date);
    if(!time.isValid()) return date;
     return dayjs.utc(date).local().format('hh:mm A');
};

export const datesTypeInDb = {
    startDate : "@startdate",
    endDate : "@enddate"
  }