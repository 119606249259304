import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  model: {
    open: false,
    type : "UPDATE",
    onClickSave: () => { },
    onClickCancel: () => { },
  },
  snack: {
    open: false,
    message: "",
    severity: "",
  },
};

export const ModelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    openModel: (state, { payload }) => {
      state.model = payload;
    },
    closeModel: (state, { payload = initialState.snack }) => {
      state.model = initialState.model;
      state.snack = payload;
    },
    closeSnack: (state) => {
      state.snack = initialState.snack;
    }
  },
  extraReducers(builder) { },
});

//Selectors

export const ModelSelector = (state) => state.model;

export const { openModel, openSnack, closeModel, closeSnack } = ModelSlice.actions;

export default ModelSlice.reducer;
