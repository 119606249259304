import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetRoomStandards, setNewEditObject } from "Redux/RoomStandards/RoomStandardsSlice";
import { setAddnewId } from "Redux/RoomStandards/RoomStandardsSlice";
import { roomStandardsSelector } from "Redux/RoomStandards/RoomStandardsSlice";

import AppSelect from "../../../components/form/AppSelect";
import "./title-right-section.scss";

function TitleRightSection() {
  const { RoomTypes, selectedRoomTypeId } = useSelector(roomStandardsSelector);
  const dispatch = useDispatch();

  const handleSelect = (e, newValue) => {
    dispatch(GetRoomStandards({ id: (newValue.id ? newValue.id : e.target.value) }));
    dispatch(setAddnewId(newValue ? newValue.id : e.target.value));
    dispatch(
      setNewEditObject({
        key: "roomTypeId",
        value: newValue ? newValue.id : e.target.value,
      })
    );
  }

  return (
    <Box className="title-right-section">
      <Box className="title-right-section-label">Room Type</Box>
      <AppSelect
        placeholder="Choose One"
        menuItems={RoomTypes}
        selectOption={selectedRoomTypeId}
        handleSelectChange={handleSelect}
        autoComplete
        sx={{ width: 200 }}
      />
    </Box>
  );
}

export default TitleRightSection;
