import { Box, Stack } from "@mui/material";
import PreLoader from "components/ui/preLoader/PreLoader";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllRoom } from "Redux/RoomStandards/RoomStandardsSlice";
import { t } from 'i18next';
import PageHeader from "../pageHeader/PageHeader";
import PageTitle from "../pageTitle/PageTitle";
import HeaderRightSection from "./headerRightSection/HeaderRightSection";
import "./room-standards.scss";
import TableComponent from "./tableComponent/TableComponent";
import TitleRightSection from "./titleRightSection/TitleRightSection";
import { CampusSelector } from '../../Redux/Campus/campusSlice';

function RoomStandards() {
  const dispatch = useDispatch();
  const { authorization,
    name,
    isGetAllLoading,
    Location } = useSelector(CampusSelector)

  var Campusname = sessionStorage.getItem("campusName");
  var campusLocation = sessionStorage.getItem("location");

  useEffect(() => {
    dispatch(GetAllRoom());
  }, [authorization]);

  return (
    <Box className="parent-flex">
      <Box component='div' className='room-standards sticky-top-head'>
        <PageHeader
          headerTitle={name === "" ? Campusname : name}
          headerSubTitle={Location === "" ? campusLocation : Location}
          headerRight={<HeaderRightSection />}
          iscampusDropdown={true}
        />
        <PageTitle
          pageTitle={t("RoomStandard.title")}
          pageRightSection={<TitleRightSection />}
        />
      </Box>
      {isGetAllLoading ? (
        <Loading />
      ) : (<TableComponent />)}
    </Box>
  );
}

export default RoomStandards;

export function Loading() {
  return (
    <Stack
      sx={{ height: "60vh", width: "100%" }}
      justifyContent="center"
      alignItems="center"
    >
      <PreLoader elementLoader={true} loaderSize={1.5} />
    </Stack>
  );
}
