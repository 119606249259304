import { configureStore } from '@reduxjs/toolkit'
import campusSlice from "./Campus/campusSlice"
import categoriesSlice from './Categories/categoriesSlice';
import flooringtypeSlice from './FlooringType/flooringtypeSlice';
import taskSlice from "./Task/taskSlice";
import HighTouchSurfaceSlice from './HighTouchSurface/HighTouchSurfaceSlice';
import JobClassificationSlice from './JobClassification/JobClassificationSlice';
import RoomTypeSlice from './RoomTypes/RoomTypeSlice';
import ShiftTimesSlice from './ShiftTimes/ShiftTimesSlice';
import roomStandards from './RoomStandards/RoomStandardsSlice';
import touchsurface from "./TouchSurface/TouchSurfaceSlice";
import update from "./Update/UpdateSlice";
import model from './Model/ModelSlice';
import SchedulesSlice from './Schedules/SchedulesSlice';
import BuildingsSlice from './Buildings/BuildingsSlice';
import user from './User/UserSlice';
import IndividualRoomDetails from './IndividualRoomDetails/IndividualRoomDetailsSlice';
import JobcardSlice from './JobCards/JobcardSlice';
import BuildingroomsSlice from './BuildingRooms/BuildingroomsSlice';
import inspectionCategorySlice from './InspectionCategories/inspectionCategorySlice';
import CostCenterSlice from './CostCenter/CostCenterSlice';
import RoomTypesInspection from "./RoomTypesInspection/RoomTypesInspectionSlice";
import inspectionCategoryItemSlice from './InspectionCategoryItems/inspectionCategoryItemSlice';
import InspectionDetailsByRoom from './InspectionDetailsByRoom/InspectionDetailsByRoomSlice';
import InspectRooms from "./InspectRooms/InspectRoomsSlice";
import InspectionDatesSlice from './ChangeInspectionDates/ChangeInspectionDatesSlice';
import UnscoredInspectionSlice from '../Redux/UnscoredInspection/UnscoredInspectionSlice'
import NavigationSlice from './Navigation/NavigationSlice';
import RoomInspectionDetail from "./RoomInspectionDetail/RoomInspectionDetailSlice";
import CreateNewInspectionSlice from './CreateNewInspectionSlice/CreateNewInspectionSlice';
import ReportBuilder from './ReportBuilder/ReportBuilderSlice';
import RoomInspectionDetailByID from "./RoomInspectionDetail/RoomInspectionDetailByIDSlice";
import ReportSourceSlice from './ReportSource/ReportSourceSlice';
import ViewReports from './ViewReports/ViewReportsSlice';
import ReportDetailsSlice from './ReportDetails/ReportDetailsSlice';
import RolePermissionsSlice from './RolePermissions/RolePermissionsSlice';
import refreshToken from './refreshToken/refreshTokenSlice';

export const store = configureStore({
        reducer: {
                campus: campusSlice,
                categories: categoriesSlice,
                flooringtype: flooringtypeSlice,
                task: taskSlice,
                hightouchsurfaces: HighTouchSurfaceSlice,
                jobclassification: JobClassificationSlice,
                roomtype: RoomTypeSlice,
                shifttimes: ShiftTimesSlice,
                roomStandards,
                touchsurface,
                update,
                model,
                user,
                schedule: SchedulesSlice,
                buildings: BuildingsSlice,
                IndividualRoomDetails,
                jobcards: JobcardSlice,
                buildingrooms: BuildingroomsSlice,
                inspectioncategories: inspectionCategorySlice,
                costcenter: CostCenterSlice,
                RoomTypesInspection,
                inspectioncategoryitem: inspectionCategoryItemSlice,
                InspectionDetailsByRoom: InspectionDetailsByRoom,
                InspectRooms,
                inspectiondates: InspectionDatesSlice,
                UnscoredInspection: UnscoredInspectionSlice,
                navigation: NavigationSlice,
                RoomInspectionDetail,
                createNewInspection: CreateNewInspectionSlice,
                reportSource: ReportSourceSlice,
                reportDetails: ReportDetailsSlice,
                ReportBuilder,
                RoomInspectionDetailByID,
                ViewReports,
                rolePermissions: RolePermissionsSlice,
                refreshToken
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: false
        })
});
