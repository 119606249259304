import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "../../Api/Endpoints/endPoints";
import { GetMethod, PostMethod} from "../../Api/Methods/apiMethods";


export const getAllReportSource = createAsyncThunk('reportSource/getallreportSource', async (_, thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getallReportsource)
        return res.data.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
});

export const getAllReportSourceQueries = createAsyncThunk('reportSource/getallreportSourcequeries', async (reportSourceId, thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.fetchQueries(reportSourceId))
        return res.data.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
});


export const fetchQueryResult = createAsyncThunk('reportSource/fetchQueryResult', async ({queryData}, thunkAPI) => {
    try {
        let res = await PostMethod(EndPoints.postQuery, queryData)
        return JSON.parse(res.data.data);
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
});


const initialState = {
    isGetAllLoading: true,
    isEdit: false,
    editValue: {}, 
    error: false,
    ReportSource: [],
    parameters: [],
    queryName: '',
    queryDescription: '',
    sqlText: '',
    sortDatavalue: [],
    ReportSourceQueries:{},
}


const reportSourceSlice = createSlice({
    name:"reportSource",
    initialState,
    reducers:{
        setEdit: (state, { payload }) => {
            state.isEdit = payload;
            state.editValue = payload;
        },
        removeEdit: (state) => {
            state.isEdit = false;
            state.editValue = {};
        },
        EditAReportSource: (state, { payload }) => {
            state.ReportSource = payload;
        },
        SortAReportSource: (state, {payload}) => {
            state.ReportSource = payload;
        },
        setParameters: (state, {payload}) => {
            state.parameters = payload;
        },
        setQueryDescription: (state, {payload})=> {
            state.queryDescription = payload;
        },
        setQueryName: (state, {payload})=> {
            state.queryName = payload;
        },
        setSqlText: (state, {payload})=> {
            state.sqlText = payload;
        }, 
        setQueryResult: (state, {payload}) => {
            state.queryResult = payload;
        },
    },
    extraReducers(builder) {
        builder
        .addCase(getAllReportSource.pending, (state) => {
            state.isGetAllLoading = true;
            state.error = false;
        })
        .addCase(getAllReportSource.fulfilled, (state, action) => {
            state.isGetAllLoading = false;
            state.ReportSource = action.payload || [];
            state.error = false;
        })
        .addCase(getAllReportSource.rejected, (state, action) => {
            state.isGetAllLoading = false;
            state.ReportSource = [];              
            state.error = true;
        })
        .addCase(getAllReportSourceQueries.pending, (state) => {
            state.isGetAllLoading = true;
            state.error = false;
        })
        .addCase(getAllReportSourceQueries.fulfilled, (state, action) => {
            state.isGetAllLoading = false;
            state.ReportSourceQueries = action.payload || {};
            state.queryName = action.payload.queryName;
            state.queryDescription = action.payload.queryDescription;
            state.sqlText = action.payload.sql_Text;
            const menuItems = [
                { id: 1, name: 'Date Picker', value: 'date' },
                { id: 2, name: 'Text', value: 'string' },
                { id: 3, name: 'Number', value: 'int' }
            ];
            state.parameters = action.payload.queryParams.map((p) => {
                let menuId = menuItems.find(m => m.value === p.paramType)?.id || 0;
                return {...p, parameterId: menuId};
            });
            state.error = false;
        })
        .addCase(getAllReportSourceQueries.rejected, (state, action) => {
            state.isGetAllLoading = false;
            state.ReportSourceQueries = [];              
            state.error = true;
        })
        .addCase(fetchQueryResult.pending, (state, action) => {
            state.error = false;
        })
        .addCase(fetchQueryResult.fulfilled, (state, action) => {
            state.queryResult = action.payload;
            state.error = false;
        })
        .addCase(fetchQueryResult.rejected, (state, action) => {
            state.queryResult = [];
            state.error = true;
        })
    }
})

//Selectors
export const ReportSourceSelector = (state) => state.reportSource;

//Actions
export const { SortAReportSource, EditAReportSource, setEdit, removeEdit, setParameters, setSortData, setQueryDescription, setQueryName, setSqlText, setQueryResult} = reportSourceSlice.actions;

//Reducer
export default reportSourceSlice.reducer;