import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    update: {
        object: {},
        isAnyUpdate: false,
        modelData: {
        }
    },
};

export const UpdateSlice = createSlice({
    name: "update",
    initialState,
    reducers: {
        setUpate: (state, { payload }) => {
            state.update = payload;
        },
        setUpateObject: (state, { payload }) => {
            state.update.isAnyUpdate = true;
            state.update.modelData = payload.modelData;
            state.update.object[payload.data.id] = payload.data;
        },
        clearUpate: (state) => {
            state.update = initialState.update;
        }
    },
    extraReducers(builder) {

    },
});

//Selectors
export const UpdateSelector = (state) => state.update;

export const { setUpate, clearUpate, setUpateObject } = UpdateSlice.actions;

export default UpdateSlice.reducer;
