import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllJobClassification = createAsyncThunk("jobclassification/getalljobclassification",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllJobClassification)
            return res.data

        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue({ error: error });
        }

    })

export const GetAllJobClassificationById = createAsyncThunk(
    "jobclassification/jobclassificationbyId",
    async (id, thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getJobClassificationById(id));
            return response.data.data
        }
        catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
);

const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    JobClassification: [],
    UpdateJobClassification: {

    },
    JobClassificationById: {

    }

}

export const jobclassificationSlice = createSlice({
    name: "jobclassification",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditFalse: (state) => {
            state.isEdit = false
            state.JobClassificationById = {}
        },

    },
    extraReducers(builder) {
        builder
            .addCase(GetAllJobClassification.fulfilled, (state, action) => {
                state.JobClassification = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllJobClassification.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetAllJobClassificationById.fulfilled, (state, action) => {
                state.isLoading = false
                state.JobClassificationById = action.payload
            })
            .addCase(GetAllJobClassificationById.pending, (state, action) => {
                state.isEdit = true
                state.isLoading = true
                state.open = true
            })
            .addCase(GetAllJobClassificationById.rejected, (state, action) => {
                state.isLoading = false
                state.JobClassificationById = {}
            })
    }

});

//Selectors
export const JobClassificationSelector = (state) => state.jobclassification

export const { openModel, closeModel, EditFalse } = jobclassificationSlice.actions;

export default jobclassificationSlice.reducer;
