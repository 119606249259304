import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc');

export default function FormDatePicker({ name, ...otherProps }) {
  const [field, meta, helper] = useField(name);

  const configInputField = {
    ...field,
    ...otherProps,
    onChange: (value) => {
      helper.setValue(value)
    }
  }

  if (meta && meta.touched && meta.error) {
    configInputField.error = true;
    configInputField.helperText = meta.error;
  }
  return (
    <DateTimePicker
      className="form-input"
      {...configInputField}
      renderInput={(params) => (
        <TextField
          variant="standard"
          placeholder="same"
          fullWidth
          {...params}
        />
      )}
    />
  );
}
