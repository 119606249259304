import { EndPoints } from 'Api/Endpoints/endPoints';
import axios from 'axios';
import { setRefreshToken } from 'Redux/refreshToken/refreshTokenSlice';
import { store } from 'Redux/store';
import { ParentURL } from "../apiRoute/apiRoute"
import { JsonHeaders, GetAuthorizationToken, ClearStorage, SetAuthorization } from '../Headers/headers';

const axiosClient = axios.create();
axiosClient.defaults.baseURL = `${ParentURL.URL}api/`;

axiosClient.defaults.headers = JsonHeaders;



axiosClient.interceptors.request.use(function (config) {
  const token = GetAuthorizationToken();
  if ((token || '').trim().length > 0) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(function (response) {
  
  if(response?.headers?.authorization) {
    SetAuthorization(response.headers.authorization.replace("Bearer ", ""));
    store.dispatch(setRefreshToken());
  }
  return response;
}, function (error) {

  const token = GetAuthorizationToken();
  if ((token || '').trim().length > 0 && error?.response?.status === 401) {
    ClearStorage();
    window.location.href = '/login';
  }

  return Promise.reject(error);
});



export const PostMethod = (URL, data, config = {}) => axiosClient.post(URL, data, config);

export const GetMethod = (URL) => axiosClient.get(URL);

export const PutMethod = (URL, data) => axiosClient.put(URL, data);

export const DeleteMethod = (URL) => axiosClient.delete(URL)

export const DeleteMethodWithBody = (URL, data) => axiosClient.delete(URL, { data });