import React from 'react'
import { TextField, InputAdornment } from '@mui/material';

function FormInput({multilineRowCount, textarea, initialValue, labelName, placeholderLabel, onInputChange, name, value, error, helperText, endIcon, readOnly, inputProps = {}, InputProps = {}, type, labelProps, ...rest }) {
  return (
    <TextField id="standard-basic"
      error={error}
      helperText={helperText}
      placeholder={placeholderLabel}
      onChange={onInputChange}
      label={labelName === '' ? "" : labelName}
      name={name}
      variant="standard"
      value={value}
      className={textarea ? "form-input multiline-textbox" : "form-input"}
      rows={multilineRowCount}
      multiline={ textarea ? true : false}
      type={type ?? "text"}
      InputLabelProps={{ shrink: labelProps }}
      inputProps={{
        ...inputProps,
        readOnly: readOnly
      }}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {endIcon}
          </InputAdornment>)
      }}
      {...rest}
    />
  )
}

export default FormInput



export const SpecialCharacters = (name) => {
  return (
    name?.replace(/[^a-zA-Z0-9`!@$%*)(+=<>,.\[\]\/\\\?/:;"'}{_-\s]+/g, '') /* ==> NO trimming white spaces on both sides */
    //name.replace(/^\s+|[^a-zA-Z0-9`!@$%*)(+=<>,.\[\]\/\\\?/:;"'}{_-\s]|\s+$/g, '') /* ==> enable this trimming white spaces on both sides */
    // name?.replace(/^\s+|[^a-zA-Z0-9`!@$%*)(+=<>,.\[\]\/\\\?/:;"'}{_-\s]+/g, '') /*  ==> enable this for trimming white spaces at begining */
  )
}