// import { kdf } from "crypto-js"

import { ParentURL } from "../apiRoute/apiRoute";


export const EndPoints = {
  login: `Account/login`,
  forgotPassword: `Account/forgot-password`,
  forgotPasswordValidate: `Account/forgot-password/validate`,
  inviteTokenValidate: `Account/invite/validate`,
  resetPassword: `Account/reset-password`,
  refreshToken: `Account/refresh-token`,
  clientLogo: `Campus/logo`,

  getCampusAll: `Campus/all`,
  getCampus: (id) => `Campus/${id}`,
  selectCategory: (id) => `${ParentURL.URL}api/Campus/${id}/select`,
  saveCampus: `Campus/save`,
  getAccountTypes: `Campus/account-types`,

  getCategoriesAll: `Category/all`,
  putCategoriesAvailable: `Category/available`,
  postCategories: `Category/save`,
  putCategory: (id) => `Category/${id}/save`,
  getCategorybyId: (id) => `Category/${id}`,
  deleteCategory: (id) => `Category/${id}`,

  getAllFlooringTypes: `FlooringType/all`,
  getFlooringTypeById: (id) => `FlooringType/${id}`,
  saveFlooringType: `FlooringType/save`,
  deleteFlooringType: (id) => `FlooringType/${id}`,
  updateFlooringType: (id) => `FlooringType/${id}/save`,
  putFlooringAvailable: `FlooringType/available`,

  getSelectedCategories: `/Category/available`,
  addNewTask: `ProcedureTask/save`,
  getTaskByCategoryId: (id) => `/ProcedureTask/${id}/all`,
  getAllAvailableTask: `/ProcedureTask/available`,
  getTaskById: (id) => `ProcedureTask/${id}`,
  deleteTask: (id) => `ProcedureTask/${id}`,
  putTask: (id) => `ProcedureTask/${id}/save`,
  putTaskAvailable: `ProcedureTask/available`,

  getAllHighTouchSurface: `HightouchSurface/all`,
  getAllAvailableHighTouchSurface: `HightouchSurface/available`,
  getHighTouchSurfaceById: (id) => `HightouchSurface/${id}`,
  saveHighTouchSurface: `HightouchSurface/save`,
  deleteHighTouchSurface: (id) => `HightouchSurface/${id}`,
  putHighTouchSurface: (id) => `HightouchSurface/${id}/save`,
  putHighTouchSurfaceAvailable: `HightouchSurface/available`,

  getAllJobClassification: `JobClassification/all`,
  getJobClassificationById: (id) => `JobClassification/${id}`,
  saveJobClassification: `JobClassification/save`,
  deleteJobClassification: (id) => `JobClassification/${id}`,
  putJobClassification: (id) => `JobClassification/${id}/save`,

  getAllRoomType: `RoomType/all`,
  getAllAvailableRoomType: `RoomType/available`,
  getRoomTypeById: (id) => `RoomType/${id}`,
  saveRoomType: `RoomType/save`,
  deleteRoomType: (id) => `RoomType/${id}`,
  putRoomType: (id) => `RoomType/${id}/save`,
  putRoomTypeAvailable: `RoomType/available`,

  getAllShiftTimes: `Shift/all`,
  getAllShiftsByCampus: "shift/campus",
  getShiftTimesById: (id) => `Shift/${id}`,
  saveShiftTimes: `Shift/save`,
  deleteShiftTimes: (id) => `Shift/${id}`,
  putShiftTimes: (id) => `Shift/${id}/save`,
  putShiftTimesBulk: `Shift/save/all`,

  allRoomTypeTaskByRoomId: (id) => `RoomTypeTask/${id}/all`,
  roomTypeTask: (id) => `RoomTypeTask/${id}`,
  postRoomTypeTask: `RoomTypeTask/save`,
  deleteRoomTypeTask: (id) => `RoomTypeTask/${id}`,
  bulkPutRoomTypeTask: (id) => `RoomTypeTask/save/${id}/all`,

  RoomTypeHightouchSurfaceById: (id) => `RoomTypeHightouchSurface/${id}`,
  postRoomTypeHightouchSurface: `RoomTypeHightouchSurface/save`,

  getAllSchedule: `Schedule/all`,
  getScheduleById: (id) => `Schedule/${id}`,
  saveSchedule: `Schedule/save`,
  deleteSchedule: (id) => `Schedule/${id}`,
  putSchedule: (id) => `Schedule/${id}/save`,
  putScheduleBulk: `Schedule/save/all`,

  getAllBuilding: `Building/all`,
  getAvailableBuilding: `Building/available`,
  getBuildingById: (id) => `Building/${id}`,
  saveBuilding: `Building/save`,
  deleteBuilding: (id) => `Building/${id}`,
  putBuilding: (id) => `Building/${id}/save`,
  putBuildingBulk: `Building/save/all`,

  getAllsupervisorUsers: `User/supervisor-users`,

  getSchedule: "Schedule",
  postUserRole: "User/save-user-role",
  postEmployee: "User/save-employee",
  getAllUser: "User/all",
  getAdminUsers: "User/admin-users",
  getExistingUsers: "User/existing-users",
  showExistingUsers: "User/show/existingusers",
  saveExistingUser: "/User/save-existing-users",
  getUserSheduleByUserId: (id) => `User/${id}`,
  deleteUser: (id) => `User/${id}`,
  putUserRole: (id) => `User/${id}/save-user-role`,
  putEmployee: (id) => `User/${id}/save-employee`,
  bulkUpdateUser: "User/save/all",
  getModulePermissions: "User/module-permission",
  checkprimary: "User/check/primaryschedule",

  getAllRoom: `BuildingRoom`,
  getIndividualRoomDetailById: (id) => `Room/${id}/all`,
  putIndividualRoomDetail: "Room/save",
  deleteIndividualRoomDetail: "Room",
  bulkPutIndividualRoom: "Room/save/all",

  getAllJobcards: `JobCard/room-types`,
  getJobCardDetails: `JobCard`,

  getAllBuildingRoom: `BuildingRoom/all`,
  getBuildingRoomById: (id) => `BuildingRoom/${id}`,
  saveBuildingRoom: `BuildingRoom/save`,
  deleteBuildingRoom: (id) => `BuildingRoom/${id}`,
  putBuildingRoom: (id) => `BuildingRoom/${id}/save`,
  putBuildingRoomBulk: `BuildingRoom/save/all`,
  getRoomByBuilding: (id) => `BuildingRoom/${id}/building`,

  getAllCostCenter: `CostCenter/all`,
  getCostCenterById: (id) => `CostCenter/${id}`,
  saveCostCenter: `CostCenter/save`,
  deleteCostCenter: (id) => `CostCenter/${id}`,
  putCostCenter: (id) => `CostCenter/${id}/save`,

  getAllBuildingFloor: `BuildingFloor`,
  getAvailableBuildingFloor: `BuildingFloor/available`,

  getInspectionCategoriesAll: `InspectionCategory/all`,
  putInspectionCategoriesAvailable: `InspectionCategory/available`,
  postInspectionCategories: `InspectionCategory/save`,
  putInspectionCategory: (id) => `InspectionCategory/${id}/save`,
  getInspectionCategorybyId: (id) => `InspectionCategory/${id}`,
  deleteInspectionCategory: (id) => `InspectionCategory/${id}`,

  getSelectedInspectionCategories: `InspectionCategory/available`,
  postInspectionCategoryItem: `InspectionCategoryItem/save`,
  getInspectionCategoryItemByCategoryId: (id) =>
    `InspectionCategoryItem/${id}/all`,
  getInspectionCategoryItemById: (id) => `InspectionCategoryItem/${id}`,
  getAllAvailableInspectionCategoryItem: `InspectionCategoryItem/available`,
  deleteInspectionCategoryItem: (id) => `InspectionCategoryItem/${id}`,
  putInspectionCategoryItem: (id) => `InspectionCategoryItem/${id}/save`,
  putInspectionCategoryItemAvailable: `InspectionCategoryItem/available`,

  getInspectionScale: `InspectionScale`,

  getInspectionItemByRoomType: (roomTypeId) =>
    `InspectionItemRoomType/${roomTypeId}/all`,
  postInspectionItemByRoomType: `InspectionItemRoomType/save`,
  putInspectionItemRoomType: (Id) => `InspectionItemRoomType/${Id}/save`,
  deleteInspectionItemRoomType: (id) => `InspectionItemRoomType/${id}`,
  bulkInspectionItemRoomType: (id) => `InspectionItemRoomType/save/${id}/all`,

  getInspectRoomsbByBuilding: (id) => `InspectRooms/${id}/building`,
  postInspectRoomsByBuilding: `InspectRooms/save`,
  getInspectRoomsBuildings: `InspectRooms/buildings`,

  getInspectionRoom: (id) => `InspectionRoom/${id}/all`,
  postInspectionRoom: `InspectionRoom/save`,
  putInspectionRoom: `InspectionRoom/save/all`,
  getInspectionRoombyId: (id) => `InspectionRoom`,
  deleteInspectionRoom: `InspectionRoom`,

  getAvailableInspectionDate: `InspectionDateChange/available`,
  UpdateInspectionDate: `InspectionDateChange/Update`,

  getUnscoredInspection: `UnScoreInspection/available`,
  DeleteUnscoredInspection: `UnScoreInspection`,

  getRoomInspectionDetail: "RoomInspectionDetail/all",
  getRoomInspectionSupervisorDetail: (id) =>
    `RoomInspectionDetail/${id}/supervisor`,
  getRoomInspectionDetailByID: (id) =>
    `RoomInspectionDetail/${id}/roomInspection`,
  getEmployeeByInspectionID: (id) =>
    `RoomInspectionDetail/allEmployees?inspectionId=${id}`,
  PostRoomInspectionDetailByID: `RoomInspectionDetail/Save`,
  PostSendMail: `RoomInspectionDetail/SendMail`,
  PostImageAgainestInspectionByID: `RoomInspectionDetail/UpdateImage`,
  deleteInspectionByID: (id) => `RoomInspectionDetail/${id}/ReInspection`,
  deleteInspection: (id) => `RoomInspectionDetail/${id}`,

  getcreateRoomInspectionConfig: "InspectRooms/configs",
  createCustomRoomInspection: "InspectRooms/save-custom-inspection",

  getallReportsource: `ReportSource/all`,
  postReportSource: `ReportSource/save`,
  putReportSource: (id) => `ReportSource/${id}/save`,
  deleteReportSource: (id) => `ReportSource/${id}`,
  postQuery: `ReportSource/FetchQuery`,
  fetchQueries: (id) => `ReportSource/${id}/Queries`,

  getAllReportBuilder: "ReportBuilder/all",
  saveReportBuilder: "ReportBuilder/Save",
  postReportBuilder: (id) => `ReportBuilder/${id}/Save`,

  getReportType: "ReportBuilder/reportTypes",
  getReportCategories: "ReportBuilder/reportCategories",
  getQueryColumns: (id) => `ReportBuilder/queryColumns?queryId=${id}`,
  fetchPreview: "ReportSource/FetchQuery",

  getQueryParams: (id) => `Report/${id}/params`,
  fetchReportData: "Report/fetchdata",

  getAllReportDetails: "ReportDetail/all",
  deleteReportDetails: (id) => `ReportDetail/${id}`,
  showreport: "ReportDetail/showreport",
  getALLViewReports: "Report/all",
  getReportDetails: (reportId) =>
    `ReportBuilder/ReportDetails?reportId=${reportId}`,
  getReportDetailByReportId: (reportId) => `ReportDetail/${reportId}`,

  getAllRolePermissions: "RolePermission/allRolePermissions",
  saveRolePermissions: "RolePermission/save",

  getVersionDetails: "App/version"
};