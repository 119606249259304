import { EndPoints } from "Api/Endpoints/endPoints";
import { PostMethod } from "Api/Methods/apiMethods";
import MySnackBar from "components/snackbar/SnackBar";
import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openModel as openCommonModel } from "Redux/Model/ModelSlice";
import { setOpenModel, setCloseModel } from "Redux/RoomStandards/RoomStandardsSlice";
import { setAddNewError } from "Redux/RoomStandards/RoomStandardsSlice";
import { GetRoomStandards } from "Redux/RoomStandards/RoomStandardsSlice";
import { roomStandardsSelector } from "Redux/RoomStandards/RoomStandardsSlice";
import { UpdateSelector } from "Redux/Update/UpdateSlice";
import AppButton from "../../../components/ui/buttons/AppButton";
import AppModal from "../../../components/ui/modal/AppModal";
import AddNew from "../addNew/AddNew";
import { RolePermissionsSelector } from 'Redux/RolePermissions/RolePermissionsSlice'
import { Modules, ErrorCode } from 'data/constants'

let initialSnack = {
  open: false,
  message: null,
  severity: null,
};

let validateArray = [
  { name: "roomTypeId", type: "number" },
  { name: "procedureTaskId", type: "number" },
  { name: "noOfUnits", type: "number", min: 0 },
  { name: "order", type: "number", min: 0 },
];

function HeaderRightSection() {
  const [snack, setSnack] = useState(initialSnack);
  const { openModel, newEditObject, selectedRoomTypeId } = useSelector(roomStandardsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { update } = useSelector(UpdateSelector);
  const { allModulePermissions } = useSelector(RolePermissionsSelector)

  const handleModalSubmit = async () => {
    let { error, data } = validator(validateArray, newEditObject);
    if (error) return dispatch(setAddNewError({ data }));
    let body = {
      noOfUnits: 0,
      order: 0,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      level2: false,
      level3: false,
      isAvailable: true,
      ...newEditObject,
    };
    try {
      let res = await PostMethod(EndPoints.postRoomTypeTask, body);
      dispatch(GetRoomStandards({ id: selectedRoomTypeId }));
      dispatch(setCloseModel({ open: false }));
      if (res.data.isSuccess) {
        setSnack({
          open: true,
          severity: "success",
          message: t("RoomStandard.add-success"),
        });
      }
      else if (!(res.data.isSuccess) && res.data.errorCode === ErrorCode.DataExists) {
        setSnack({
          open: true,
          severity: "error",
          message: t('DuplicateData.duplicate-Data'),
        })
      }
      else {
        setSnack({
          open: true,
          severity: "error",
          message: t("RoomStandard.add-error"),
        });
      }
    } catch (err) {
      dispatch(setCloseModel({ open: false }));
      setSnack({
        open: true,
        severity: "error",
        message: t("RoomStandard.add-error"),
      });
    }
  };

  return (
    <>
      <AppButton
        iconTrue={true}
        buttonName="Prev Step"
        variantType="blueOutline"
        buttonIcon="leftArrow"
        handleOnClick={() => update.isAnyUpdate ? dispatch(openCommonModel({ ...update.modelData, isUpdate: true, handleClose: () => navigate('/room-types') })) : navigate('/room-types')}
      />
      <AppButton
        iconTrue={true}
        iconPosEnd={true}
        buttonName="Next Step"
        variantType="blueOutline"
        buttonIcon="rightArrow"
        handleOnClick={() => update.isAnyUpdate ? dispatch(openCommonModel({ ...update.modelData, isUpdate: true, handleClose: () => navigate('/touch-surfaces') })) : navigate('/touch-surfaces')}
      />
      {allModulePermissions && allModulePermissions[Modules.RoomTypes].create && <AppModal
        Open={openModel}
        handleClickOpen={() => dispatch(setOpenModel({ open: true }))}
        handleClose={() => dispatch(setCloseModel({ open: false }))}
        iconTrue={true}
        iconPosEnd={true}
        buttonName="New"
        variantType="greenButton"
        buttonIcon="plus"
        modalTitle={t('RoomStandard.add-modal')}
        dialogBody={<AddNew />}
        submitButtonLabel={t('SaveModal.btn-text')}
        onModalSubmit={(e) => handleModalSubmit(e)}
      />}
      {update.isAnyUpdate && <AppButton handleOnClick={update.modelData.onClickSave} iconTrue={true} buttonName={'Save'} variantType='blueButton' buttonIcon='save' />}
      <MySnackBar
        snackOpen={snack.open}
        onClose={() => setSnack({ ...snack, open: false })}
        message={snack.message}
        position1={"top"}
        position2={"right"}
        severity={snack.severity}
      />
    </>
  );
}

export default HeaderRightSection;


function validator(validatorArray, object) {
  let errObject = {
    error: false,
    data: {}
  };
  validatorArray.map((prop) => {
    let { name, type } = prop;
    if (typeof object[name] !== type) {
      errObject.error = true;
      errObject.data[name] = {
        error: "Required",
        iserror: true
      }
    }
  });
  return errObject;
}

