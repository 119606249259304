const Constants = {
  HASH_SALT_KEY: '$2a$10$CwTycUXWue0Thq9StjUM0u',
  OTHER_variable: 'value'
}

export const UserRoles = {
  Supervisor: 'Supervisor',
  Manager: 'Manager',
  SuperAdmin: 'Super Admin'
}

export const Modules = {
  Employees : 0,
  Supervisor : 1,
  ProcedureCategory : 2,
  ProcedureTasks : 3,
  RoomTypes : 4,
  Shifts : 5,
  Schedules : 6,
  JobClassification : 7,
  Buildings : 8,
  BuildingRooms : 9,
  WorkloadingReports : 10,
  HighTouchSurfaces : 11,
  Jobcards : 12,
  InspectionCategories : 13,
  InspectionTasks : 14,
  InspectionDetails : 15,
  NewInspection : 16,
  ChangeInspectionDate : 17,
  InspectionResults : 18,
  InspectionReports : 19,
  UnscoredInspection : 20,
  Campus : 21,
  UserRolesMapping : 22,
  RolePermissionMapping : 23,
  AdminManagerUserCreation : 24,
  FlooringTypes : 25,
  RoomTypeTask : 26,
  CostCenter : 27,
  BuildingFloor : 28,
  InspectionDateChange : 29,
  ReportSource : 30,
}

export const ErrorCode = {
  DataExists : "Err_IR010"
}

export const itemPerPage = 25; 
export default Constants
