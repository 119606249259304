import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod } from "../../Api/Methods/apiMethods";



export const GetAvailableBuilding = createAsyncThunk(
    "createNewInspection/getAvailableBuilding",
    async (_, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getInspectRoomsBuildings);
            return res.data.data ?? []

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const GetInspectionLinks = createAsyncThunk(
    "createNewInspection/getInspectionLinks",
    async (_, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getcreateRoomInspectionConfig)
            return res.data.data ?? []
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const initialState = {
    Buildings: [],
    isLoading: false,
    open: null,
    isGetAllLoading: false,
    SelectedLinkId: null,
    selectedBuildings: [],
    filteredBuildings: [],
    InspectionConfig: []
}

export const createNewInspectionSlice = createSlice({
    name: "createNewInspection",
    initialState,
    reducers: {
        openModel: (state, action) => {
            state.open = true
        },
        closeModel: (state, action) => {
            state.open = false
            state.selectedBuildings = []
            state.filteredBuildings = state.Buildings
        },
        setSelectedID: (state, action) => {
            state.SelectedLinkId = action.payload
        },
        setselectedBuildings: (state, action) => {
            state.selectedBuildings = action.payload
        },
        Searchbuildings: (state, action) => {
            state.filteredBuildings = state.Buildings?.filter(x => x.name.includes(action.payload))
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAvailableBuilding.fulfilled, (state, action) => {
                state.Buildings = action.payload
                state.filteredBuildings = action.payload
            })
            .addCase(GetAvailableBuilding.rejected, (state, action) => {
                state.Buildings = []
                state.filteredBuildings = []
            })
            .addCase(GetInspectionLinks.fulfilled, (state, action) => {
                state.InspectionConfig = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetInspectionLinks.pending, (state, action) => {
                state.isGetAllLoading = true
            })
            .addCase(GetInspectionLinks.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
    }

});

//Selectors
export const createNewInspectionSelector = (state) => state.createNewInspection

export const { openModel, closeModel, setSelectedID, setselectedBuildings, Searchbuildings } = createNewInspectionSlice.actions;

export default createNewInspectionSlice.reducer;
