import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod, PostMethod } from "Api/Methods/apiMethods";
import { findSelectedUserRoleId } from "pages/users/addNew/AddNew";


export const GetAllUser = createAsyncThunk("user/getAllUser", async (role) => {
  try {
    let { data } = await GetMethod(EndPoints.getAllUser);
    return data.data.sort((x, y) => y.status - x.status);
  } catch (err) {
    return err;
  }
});

export const GetAdminUsers = createAsyncThunk("user/getAdminUsers", async () => {
  try {
    let { data } = await GetMethod(EndPoints.getAdminUsers);
    return data.data? data.data.sort((x, y) => y.status - x.status): [];
  } catch (err) {
    return err;
  }
});

export const ShowExistingUsers = createAsyncThunk("user/showExistingUsers", async () => {
  try {
    let { data } = await PostMethod(EndPoints.showExistingUsers);
    return data.data;
  } catch (err) {
    return err;
  }
});

export const SaveExistingUser = createAsyncThunk("user/saveExistingUser", async (data) => {
  const userIds = data;
  try {
    let { data } = await PostMethod(EndPoints.saveExistingUser, userIds);
    return data.data ? data.data : [];
  } catch (err) {
    return err;
  }
});

export const GetExistingUsers = createAsyncThunk("user/getExistingUser", async () => {
  try {
    let { data } = await GetMethod(EndPoints.getExistingUsers);
    return data.data
  } catch (err) {
    return err;
  }
});

export const GetEditUserValue = createAsyncThunk(
  "user/getEditUserValue",
  async (id) => {
    try {
      const { data } = await GetMethod(EndPoints.getUserSheduleByUserId(id));
      let obj = {};
      data.data.schedules.map((item) => {
        obj[item.id] = {
          scheduleId: item.id,
          isPrimary: item.isPrimary === 1? true: false,
          ...obj[item.id],
          [`week${item.weekId}`]: {
            ...item,
          },
        };
        return "";
      });
      data.data.schedules = Object.values(obj);
      const finalData = {
        ...data,
        data: {
          ...data.data,
          productivityRatio: +data?.data?.productivityRatio?.toFixed(2),
        },
      };
      return finalData.data
    } catch (err) {
      return err;
    }
  }
);
const initialState = {
  selectedUserRole: findSelectedUserRoleId(),
  selectedUserRoleError: false,

  edit: false,
  setEditModelLoading: false,
  editData: {},
  adminUsers: [],
  existingUsers: [],
  userTable: [],
  isExistingUsers: false,
  isLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setInitialState: (state) => {
      state.selectedUserRole = findSelectedUserRoleId();
      state.selectedUserRoleError = false;

      state.edit = false;
      state.etEditModelLoading = false;
      state.editData = {};
    },
    setSelectedUserRole: (state, { payload }) => {
      state.selectedUserRole = payload;
    },
    setSelectedUserRoleErrorTrue: (state) => {
      state.selectedUserRoleError = true;
    },
    setSelectedUserRoleErrorFalse: (state) => {
      state.selectedUserRoleError = false;
    },
    UpdateUser: (state, { payload }) => {
      state.userTable = state.userTable.map((item) => item.id === payload.id ? { ...item, status: payload.checked } : item);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllUser.fulfilled, (state, action) => {
        state.userTable = action.payload;
        state.isLoading = false;
      })
      .addCase(GetAllUser.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(GetAllUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAdminUsers.fulfilled, (state, action) => {
        state.adminUsers = action.payload || [];
        state.isLoading = false;
      })
      .addCase(GetAdminUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ShowExistingUsers.fulfilled, (state, action) => {
        state.isExistingUsers = action.payload;
      })
      .addCase(GetExistingUsers.fulfilled, (state, action) => {
        state.existingUsers = action.payload || [];
      })
      .addCase(GetEditUserValue.fulfilled, (state, action) => {
        state.edit = true;
        state.editData = action.payload;
        state.selectedUserRole = action.payload.roleId;
        state.setEditModelLoading = false;
      })
      .addCase(GetEditUserValue.rejected, (state, action) => {
        state.setEditModelLoading = false;
      })
      .addCase(GetEditUserValue.pending, (state) => {
        state.edit = true;
        state.setEditModelLoading = true;
      });
  },
});

export const userSelector = (state) => state.user;

export const {
  setSelectedUserRole,
  setSelectedUserRoleErrorTrue,
  setSelectedUserRoleErrorFalse,
  setInitialState,
  UpdateUser,
  updateExistingUser,
} = userSlice.actions;

export default userSlice.reducer;
