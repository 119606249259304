import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import PreLoader from "components/ui/preLoader/PreLoader";

const PageLoaderContext = React.createContext({});

export const useFullScreenLoader = () => {
    return React.useContext(PageLoaderContext);
};
export default function FullScreenLoaderContextProvider({ children }) {
    const [open, setClose] = useState(false);

    const onOpen = () => {
        setClose(true);
    };
    const onClose = () => {
        setClose(false);
    };
    return (
        <PageLoaderContext.Provider value={{ onOpen, onClose }}>
            {children}
            {open && (
                <Box as={OverLay}>
                    <PreLoader elementLoader={true} loaderSize={1.5} />
                </Box>
            )}
        </PageLoaderContext.Provider>
    );
}

const OverLay = styled.div(() => ({
    position: "absolute",
    zIndex: 10000,
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100vh",
}));
