import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import * as yup from "yup";
import bcrypt from 'bcryptjs';
import { useLocation } from "react-router-dom";
import stateJson from "./state.json";
import "./add-new.scss";
import InputField from "components/form/InputField";
import SelectField from "components/form/SelectField";
import RadioButton from "components/form/RadioButton";
import AddNewEmployee from "./AddNewEmployee";
import { useSelector } from "react-redux";
import { userSelector } from "Redux/User/UserSlice";
import { useDispatch } from "react-redux";
import { setSelectedUserRole } from "Redux/User/UserSlice";
import { Loading } from "pages/roomStandards/RoomStandards";
import { setInitialState } from "Redux/User/UserSlice";
import FormInput, { SpecialCharacters } from '../../../components/form/FormInput'
import AppButton from '../../../components/ui/buttons/AppButton'
import Constants from "data/constants";


var role = sessionStorage.getItem("userRole");

// user role with id
export const ROLE_ID = {
  SUPERADMIN: 3,
  MANAGER: 2,
  SUPERVISOR: 1,
  EMPLOYEE: 0,
};

// user role with label and id for radio button
const alluser = [
  { label: "ACUITY Staffs", value: ROLE_ID.SUPERADMIN },
  { label: "Manager", value: ROLE_ID.MANAGER },
  { label: "Supervisor", value: ROLE_ID.SUPERVISOR },
  { label: "Employee", value: ROLE_ID.EMPLOYEE },
];

function AddNew(props, ref) {
  const { selectedUserRole, edit, setEditModelLoading } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    }
  }, []);
  if (edit && setEditModelLoading) return <Loading />;
  return (
    <>
      {selectedUserRole !== ROLE_ID.EMPLOYEE ? (
        <AddedSuperVisorAndManager ref={ref} />
      ) : (
        <AddNewEmployee ref={ref} />
      )}
    </>
  );
}

export default React.forwardRef(AddNew);

export const AddedSuperVisorAndManager = React.forwardRef(
  AddedSuperVisorAndManagerRef
);

function AddedSuperVisorAndManagerRef(prop, ref) {
  let location = useLocation();
  const LocationArray = location.pathname.split("-")
  const isAdmin = LocationArray[0] == '/admin'
  const [password, setPassword] = useState("*******");
  const [editPassword, setEditPassword] = useState(false);
  const [errPassword, setErrPassword] = useState(false);

  const { selectedUserRole, edit, editData } = useSelector(userSelector);
  const formicRef = useRef({ submit: () => { } });
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    submit: handleSubmitAction,
  }));

  useEffect(()=>{
    if(isAdmin) dispatch(setSelectedUserRole(3));
  },[])

  const handleSubmitAction = async () => {
    const errors = await formicRef.current.validateForm();
    if (password === "" || Object.keys(errors).length) {
      setErrPassword(true);
      formicRef.current.handleSubmit();
      return { isValid: false, response: formicRef.current.values };
    }
    setErrPassword(false);
    const salt = Constants.HASH_SALT_KEY;
    const pss = password === "*******" ? {} : { password: bcrypt.hashSync(password, salt) }
    return { isValid: true, response: { ...postBody(formicRef.current.values), ...pss } };
  };

  function postBody(values) {
    return {
      id: values.id,
      firstName: values.firstName,
      middleInitial: values.middleInitial,
      lastName: values.lastName,
      roleId: values.roleId,
      state: values.state,
      email: values.email,
    };
  }
  return (
    <Grid
      container
      className="add-new-users modal-form-grid"
      direction={"row"}
      rowSpacing={1}
      columnSpacing={{ xs: 0, sm: 2, md: 5 }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      <Grid item container xs={12} sm={12} md={12} rowSpacing={1} columnSpacing={3}
      >
        {!isAdmin && <Grid item xs={12} className="modal-form-cell">
          <Box component='div' className="user-role-section">
            <RadioButton
              name={"roleId"}
              label="User Role"
              data={edit ? userRole().filter(item => selectedUserRole === item.value) : userRole()}
              onChange={(e) =>
                dispatch(setSelectedUserRole(parseInt(e.target.value)))
              }
              value={selectedUserRole}
            />
          </Box>
        </Grid>}
        <Formik
          initialValues={edit ? editData : {
            firstName: "",
            middleInitial: "",
            lastName: "",
            email: "",
            state: "",
          }}
          innerRef={formicRef}
          validationSchema={yup.object().shape({
            firstName: yup.string().required("Please fill the First Name"),
            lastName: yup.string().required("Please fill the Last Name"),
            email: yup
              .string()
              .email("Please enter valid email")
              .required("Please enter email"),
            state: yup.string(),
          })}
          enableReinitialize
          onSubmit={(val) => {
            console.log(val);
          }}
        >
          {(formik) => (
            <>
              <Grid item xs={12} sm={6} className="modal-form-cell">
                <InputField onChange={(e) => {
                  formik.setFieldValue('firstName', SpecialCharacters(e.target.value));
                }}label="First Name" type="text" name="firstName" />
              </Grid>
              <Grid item xs={12} sm={6} className="modal-form-cell">
                <InputField onChange={(e) => {
                  formik.setFieldValue('middleInitial', SpecialCharacters(e.target.value));
                }}label="Middle Name" type="text" name="middleInitial" />
              </Grid>
              <Grid item xs={12} sm={6} className="modal-form-cell">
                <InputField onChange={(e) => {
                  formik.setFieldValue('lastName', SpecialCharacters(e.target.value));
                }}label="Last Name" type="text" name="lastName" />
              </Grid>
              <Grid item xs={12} sm={6} className="modal-form-cell">
                <InputField onChange={(e) => {
                  formik.setFieldValue('email', SpecialCharacters(e.target.value));
                }}label="Email" type="email" name="email" />
              </Grid>
              <Grid item xs={12} sm={6} className="modal-form-cell">
                <SelectField
                  label="State"
                  name="state"
                  options={stateJson.map((i) => ({
                    id: i.postal,
                    name: i.state,
                  }))}
                />
              </Grid>
              {edit && (
                <Grid item xs={12} className="modal-form-cell" display={"flex"} alignItems={"center"}>
                  <FormInput value={password} error={errPassword} helperText={errPassword ? "Please enter password" : false} onChange={(e) => setPassword(e.target.value)} readOnly={!editPassword} className="modal-form-cell" label="Password" name="password" />
                  <AppButton className="modal-form-cell"
                    handleOnClick={() => { setPassword(""); setEditPassword(true) }}
                    buttonName={"Reset Password"}
                    variantType={"blueOutline"}
                  />
                </Grid>
              )}
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}


export function findSelectedUserRoleId() {
  if (role === "Super Admin") {
    return ROLE_ID.MANAGER;
  }
  else if (role === "Manager") {
    return ROLE_ID.SUPERVISOR;
  }
  else if (role === "Supervisor") {
    return ROLE_ID.EMPLOYEE;
  }
  else return null;
}

export function userRole() {
  const role = sessionStorage.getItem("userRole");
  let UserRole = [];
  if (role === "Super Admin") UserRole = [alluser[1], alluser[2], alluser[3]];
  if (role === "Manager") UserRole = [alluser[2], alluser[3]];
  if (role === "Supervisor") UserRole = [alluser[3]];
  return UserRole;
};