import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  ListSubheader,
} from "@mui/material/";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";

function AppSelect({
  labelName,
  labelProps,
  labelVisible,
  menuItems,
  defaultValue,
  disabled = false,
  selectOption,
  handleSelectChange,
  error,
  endIcon,
  helperText,
  isSuperVisor,
  placeholder,
  name,
  isNestedMenuItems = false,
  multiple = false,
  autoComplete = false,
  sx = {},
  ...rest
}) {
  const [categorizedMenuItems, setCategorizedMenuItems] = useState([]);
  useEffect(() => {
    if (isNestedMenuItems) {
      const primaryBuildings = menuItems ? menuItems.filter((bl) => bl.isPrimary):[];
      const secondaryBuildings = menuItems ? menuItems.filter((bl) => !bl.isPrimary):[];
      setCategorizedMenuItems([primaryBuildings, secondaryBuildings]);
    }
  }, []);

  return (
    <FormControl
      variant="standard"
      className="app-select"
      error={error}
      disabled={disabled}
    >
      {labelVisible && !autoComplete ? (
        <InputLabel id="demo-simple-select-standard-label">
          {labelName}
        </InputLabel>
      ) : null}
      {autoComplete ? (
        <Autocomplete
          disableClearable
          options={menuItems}
          getOptionLabel={(option) => option.name}
          onChange={handleSelectChange}
          label={labelName}
          value={
            menuItems?.find((i) => i?.id === selectOption) ?? {
              id: 0,
              name: "",
            }
          }
          sx={sx}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.id}>
                {option.name}
              </Box>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={labelName}
                placeholder={placeholder}
                variant="standard"
                className="form-input"
              />
            );
          }}
        ></Autocomplete>
      ) : isNestedMenuItems ? (
        <Select
          name={name}
          labelId="select-label-id"
          id="select-id"
          value={selectOption}
          onChange={(value) => {
            handleSelectChange(value);
          }}
          label={labelName}
          error={error}
          multiple={multiple}
          {...rest}
          endAdornment={endIcon}
          defaultValue={defaultValue}
          inputProps={{ shrink: labelProps }}
        >
          {!categorizedMenuItems[0]?.length ? (
            <ListSubheader />
          ) : (
            <ListSubheader className="buildings-list-heading">
              Primary buildings
            </ListSubheader>
          )}
          {categorizedMenuItems[0]?.map((menuItem, key) => (
            <MenuItem
              className="highlight-select-menu"
              key={key}
              value={menuItem.id}
            >
              {menuItem.name}
            </MenuItem>
          ))}
          {!categorizedMenuItems[0]?.length ? (
            <ListSubheader/>
          ) : (
            <ListSubheader className="buildings-list-heading">
              Rest of the Buildings
            </ListSubheader>
          )}
          {categorizedMenuItems[1]?.map((menuItem, key) => (
            <MenuItem key={key} value={menuItem.id}>
              {menuItem.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          name={name}
          labelId="select-label-id"
          id="select-id"
          value={selectOption}
          onChange={(value) => {
            handleSelectChange(value);
          }}
          label={labelName}
          error={error}
          multiple={multiple}
          {...rest}
          endAdornment={endIcon}
          defaultValue={defaultValue}
          inputProps={{ shrink: labelProps }}
        >
          {menuItems === null || undefined
            ? null
            : menuItems?.map((menuItem, key) => (
                <MenuItem key={key} value={menuItem.id}>
                  {multiple && (
                    <Checkbox checked={selectOption?.includes(menuItem.id)} />
                  )}{" "}
                  {menuItem.name}
                </MenuItem>
              ))}
        </Select>
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default AppSelect;
