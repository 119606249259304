import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timer: 0
};

export const refreshTokenSlice = createSlice({
    name: 'refreshToken',
    initialState,
    reducers: {
        setRefreshToken: (state) => {
            state.timer = 0;
        },
        updateRefreshToken: (state) => {
            state.timer++;
        }
    }
});

export const refreshTokenSelector = (state) =>  state.refreshToken;

export const { setRefreshToken, updateRefreshToken } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;