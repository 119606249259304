import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetMethod, PostMethod } from "Api/Methods/apiMethods";
import { EndPoints } from "Api/Endpoints/endPoints";


const initialState = {
    jobCards: [],
    isLoading: false,
    SelectedJobCards: [],
    jobCardDetails: [],
    isjobCardLoading: false,
    togglePrintData: false

};

export const GetAllJobCards = createAsyncThunk("jobcards/getalljobcards", async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAllJobcards)
        return {data: res.data}
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
}
)


export const GetJobCardDetails = createAsyncThunk("jobcards/getjobcarddetails", async (data, thunkAPI) => {
    try {
        let res = await PostMethod(EndPoints.getJobCardDetails, data)
        return res.data.data ?? []

    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
}
)


export const jobcardsSlice = createSlice({
    name: "jobcards",
    initialState,
    reducers: {
        gettheSelectedID: (state, action) => {
            state.SelectedJobCards = action.payload
        },
        clearIds: (state, action) => {
            state.SelectedJobCards = []
        },
        togglePrint: (state) => {
            state.togglePrintData = !state.togglePrintData
        },
        setJobCardDetails: (state, action) => {
            state.jobCardDetails = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllJobCards.fulfilled, (state, { payload }) => {
                state.jobCards = payload.data
                state.isLoading = false
            })
            .addCase(GetAllJobCards.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(GetAllJobCards.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(GetJobCardDetails.fulfilled, (state, action) => {
                state.jobCardDetails = action.payload
                state.isjobCardLoading = false
            })
            .addCase(GetJobCardDetails.pending, (state, action) => {
                state.isjobCardLoading = true
            })
            .addCase(GetJobCardDetails.rejected, (state, action) => {
                state.isjobCardLoading = false
            })

        },

});

//Selectors
export const JobCardSelector = (state) => state.jobcards;

export const { gettheSelectedID, togglePrint, clearIds, setJobCardDetails } = jobcardsSlice.actions


export default jobcardsSlice.reducer;
