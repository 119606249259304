import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from '../../Api/Endpoints/endPoints';
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetAllBuildings = createAsyncThunk("buildings/getallbuildings",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllBuilding)
            return res.data.data ?? []

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    })

export const GetAllSupervisorUsers = createAsyncThunk("buildings/getallsupervisorusers",
    async (thunkAPI) => {
        try {
            let res = await GetMethod(EndPoints.getAllsupervisorUsers)

            return res.data.data ?? []

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    })


const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    Buildings: [],
    Users: [],
    UpdateError: false,
    UpdateBuilding: {

    }
}

export const buildingsSlice = createSlice({
    name: "buildings",
    initialState,
    reducers: {
        openDialog: (state) => {
            state.open = true
        },
        closeDialog: (state) => {
            state.open = false
        },
        Editbuildings: (state, { payload }) => {
            state.Buildings = state.Buildings.map((item => item.id === payload.data.id ? payload.data : item))
            state.UpdateBuilding[payload.data.id] = { id: payload.data.id, ...payload.data };
        },
        ClearUpdate: (state) => {
            state.UpdateBuilding = {}
        },
        ToggleUpdateError: (state, action) => {
            state.UpdateError = action.payload
        },

    },
    extraReducers(builder) {
        builder
            .addCase(GetAllBuildings.fulfilled, (state, action) => {
                state.Buildings = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllBuildings.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetAllSupervisorUsers.fulfilled, (state, action) => {
                state.Users = action.payload

            })
    }

});

//Selectors

export const BuildingsSelector = (state) => state.buildings


export const { openDialog, closeDialog, Editbuildings, ClearUpdate, ToggleUpdateError, SwitchSupervisor } = buildingsSlice.actions;


export default buildingsSlice.reducer;
