import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { EndPoints } from "../../Api/Endpoints/endPoints"
import { GetMethod } from "../../Api/Methods/apiMethods"

const initialState = {
    isLoading: false,
    isGetAllLoading: false,
    open: false,
    isModalEditable: false,
    selectedCategories: [],
    currentCategories: null,
    task: {
        name: "",
        procedureCategoryId: 0,
        isSf: false,
        isUnit: false,
        minPerSf: 0,
        minPerUnit: 0,
        minPer1000Sf: 0,
        sfPerHour: 0,
        isAvailable: true,
        isProjectTask: false,
        sfOrUnit: "SF",
    },
    updateTaskData: {

    },
    isEdit: false,
    tasks: [],
    isTaskAdded: false,
    error: "",
}

export const getSelectedCategories = createAsyncThunk("task/selectedCategories", async () => {
    let response = await GetMethod(EndPoints.getSelectedCategories)
    return response.data.data
})

export const getTaskByCategoryId = createAsyncThunk("task/getTasks", async (id) => {
    let response = await GetMethod(EndPoints.getTaskByCategoryId(id))
    return response.data.data
})

export const GetTaskByid = createAsyncThunk(
    "task/gettaskbyid",
    async (id, thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getTaskById(id));
            return response.data.data;

        } catch (error) {
            console.log('Error while fetch Get Campus By Id', error);
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
);


const taskSlice = createSlice({
    name: "task",
    initialState,
    reducers: {
        setCurrentCategories: (state, action) => {
            state.currentCategories = action.payload
        },
        setTask: (state, action) => {
            state.tasks = { ...state.tasks, ...action.payload }
        },
        openModal: (state) => {
            state.open = true
        },
        closeModal: (state) => {
            state.open = false
            state.task = {
                name: "",
                procedureCategoryId: 0,
                isSf: false,
                isUnit: false,
                minPerSf: 0,
                minPerUnit: 0,
                minPer1000Sf: 0,
                sfPerHour: 0,
                isAvailable: true,
                isProjectTask: false,
                sfOrUnit: "SF",
            }
        },
        editModal: (state) => {
            state.isModalEditable = false
            state.task = {
                name: "",
                procedureCategoryId: 0,
                isSf: false,
                isUnit: false,
                minPerSf: 0,
                minPerUnit: 0,
                minPer1000Sf: 0,
                sfPerHour: 0,
                isAvailable: true,
                isProjectTask: false,
                sfOrUnit: "SF",
            }
        },
        EditTask: (state, { payload }) => {
            state.tasks = state.tasks.map((item => item.id === payload.data.id ? payload.data : item))
            state.updateTaskData[payload.data.id] = { id: payload.data.id, isAvailable: payload.data.isAvailable };
        },
        ClearUpdate: (state) => {
            state.updateTaskData = {}
        }


    },
    extraReducers: (builder) => {
        builder.addCase(getSelectedCategories.pending, (state) => {
            state.isGetAllLoading = true
        })
        builder.addCase(getSelectedCategories.fulfilled, (state, action) => {
            state.isGetAllLoading = false
            state.isTaskAdded = false
            state.selectedCategories = action.payload
            state.currentCategories = action.payload[0]
        })
        builder.addCase(getSelectedCategories.rejected, (state, action) => {
            state.isGetAllLoading = false
            state.selectedCategories = []
            state.error = action.payload
        })
        builder.addCase(getTaskByCategoryId.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getTaskByCategoryId.fulfilled, (state, action) => {
            state.isLoading = false
            state.tasks = action.payload
        })
        builder.addCase(getTaskByCategoryId.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(GetTaskByid.pending, (state) => {
            state.isLoading = true
            state.isModalEditable = true
            state.open = true
        })
        builder.addCase(GetTaskByid.fulfilled, (state, action) => {
            state.isLoading = false
            state.task = { ...action.payload, sfOrUnit: action.payload.isSf ? "SF" : "UNIT" }
            state.taskById = action.payload
        })
        builder.addCase(GetTaskByid.rejected, (state) => {
            state.isLoading = false
            state.isModalEditable = false;
            state.open = false;
            state.taskById = {}
        })
    },
})

//Actions
export const { openModal, closeModal, editModal, setCurrentCategories, setTask, EditTask, ClearUpdate } = taskSlice.actions

export default taskSlice.reducer
