export const myRoutes = {
    login: "/login",
    forgotpassword: "/forgot-password",
    resettoken: "/reset",
    invite : "/invite",
    resetpassword: "/reset-password",
    setPassword : "/set-password",
    success: "/success",
    home: "/*",
    nopage: "*",
    campus: "/campus"
}