import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "Api/Methods/apiMethods";


const initialState = {
  selectedID : null,
  selectedRoom: {},
  selectedRoomId : null,
  RoomInspectionDetailByID: {inspectionDate: "", inspectionId: null, roomDetails: []},
  skipedRooms : [],
  IsReInspection : false,
  inspectionScale: [],
  supervisorDetail: [],
  isTabelLoading: false,
  error: false,
};

export const GetAllRoomInspectionDetailByID = createAsyncThunk(
    "RoomInspectionDetail/getRoomInspectionDetailByID",
    async ({id, isReInspection}, thunkAPI) => {
      try {
        thunkAPI.dispatch(setSelectedID(id));
        let [{data}, {data : inspectionScale}] = await Promise.all([GetMethod(EndPoints.getRoomInspectionDetailByID(id)), GetMethod(EndPoints.getInspectionScale)]);
        if(data.roomDetails.length)  data.roomDetails.sort((a, b) => {
          if(a.roomNumber < b.roomNumber) { return -1; }
          if(a.roomNumber > b.roomNumber) { return 1; }
          return 0;
        });
        if(data.roomDetails.length && isReInspection) data.roomDetails = data.roomDetails.filter(i => i.isReInspection).map((a) => {
          return ({...a, tasks  : a.tasks.map(b => ({...b, score : null}))});
        });
        if(data && inspectionScale.isSuccess) return {...data, inspectionScale : inspectionScale.data ?? []};
        else return {inspectionDate: "", inspectionId: id, roomDetails: [], inspectionScale : []};
      } catch (err) {
        return thunkAPI.rejectWithValue({ error: err });
      }
    }
);
  
export const GetRoomInspectionSupervisorDetail = createAsyncThunk(
  "RoomInspectionDetail/getRoomInspectionSupervisorDetail",
  async (id, thunkAPI) => {
    try {
      let res = await GetMethod(EndPoints.getRoomInspectionSupervisorDetail(id));
      if (res.data.isSuccess) {
        return res?.data?.data ?? [];
      }
      return [];
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const RoomInspectionDetailByIDSlice = createSlice({
  name: "RoomInspectionDetailByID",
  initialState,
  reducers: {
   setIsReInspection : (state, {payload}) => {
    state.IsReInspection = payload;
    },
   setSelectedID : (state, {payload}) => {
    state.selectedID = payload;
   },
   setSelectedRoom : (state, { payload}) => {
    state.selectedRoom = payload;
    state.selectedRoomId = payload.roomId;
   },
   setInitialState : () => initialState,
   updateImages : (state, {payload}) => {
    let { campusInspectionDetailId, updatedImages} = payload; 
    let tasks = [...state.selectedRoom.tasks.map(i => {
        if(i.campusInspectionDetailId === campusInspectionDetailId) return  ({...i , taskImages : updatedImages}) ;
        else return ({...i});
    })];
    let data = {...state.selectedRoom , tasks};
    let roomID  = data.roomNumber;
    let RoomInspectionDetailByID = {
        ...state.RoomInspectionDetailByID , 
        roomDetails : state.RoomInspectionDetailByID.roomDetails.map(r => {
            if(r.roomNumber === roomID) return data;
            return ({...r});
        }) 
    };
    state.selectedRoom = data;
    state.RoomInspectionDetailByID = RoomInspectionDetailByID;
   },
   updateCommentsStatus : (state, { payload }) => {
        let { campusInspectionDetailId, score, comments = null} = payload; 
        let tasks = [...state.selectedRoom.tasks.map(i => {
            if(i.campusInspectionDetailId === campusInspectionDetailId) return score ? ({...i , score, comments}) : ({...i , comments});
            else return ({...i});
        })];
        let data = {...state.selectedRoom , tasks};
        // let roomID  = data.roomNumber;
        // let RoomInspectionDetailByID = {
        //     ...state.RoomInspectionDetailByID , 
        //     roomDetails : state.RoomInspectionDetailByID.roomDetails.map(r => {
        //         if(r.roomNumber === roomID) return data;
        //         return ({...r});
        //     }) 
        // };
        state.selectedRoom = data;
        // state.RoomInspectionDetailByID = RoomInspectionDetailByID;
   }
  },
  extraReducers(builder) {
    builder
      .addCase(
        GetAllRoomInspectionDetailByID.fulfilled,
        (state, { payload, meta }) => {
          let selectedRoomIndex = 0;
          if (
            state?.selectedRoomId &&
            meta.arg.type === "update" &&
            !meta.arg.isReInspection
          ) {
            selectedRoomIndex = payload.roomDetails.findIndex(
              (i) => i.roomId === state?.selectedRoomId
            );
          }
          state.RoomInspectionDetailByID = payload;
          state.inspectionScale = payload.inspectionScale;
          state.selectedRoom =
            payload.roomDetails?.[selectedRoomIndex ?? 0] ?? {};
          state.selectedRoomId =
            payload.roomDetails?.[selectedRoomIndex ?? 0]?.roomId ?? null;
          state.isTabelLoading = false;
          state.error = false;
          if (payload.roomDetails.length === 0) state.selectedID = null;
        }
      )
      .addCase(GetAllRoomInspectionDetailByID.pending, (state) => {
        state.isTabelLoading = true;
        state.error = false;
      })
      .addCase(GetAllRoomInspectionDetailByID.rejected, (state, action) => {
        state.isTabelLoading = false;
        state.error = true;
      })
      .addCase(GetRoomInspectionSupervisorDetail.fulfilled, (state, {payload}) => {
        state.supervisorDetail = payload;
      });
  },
});

export const RoomInspectionDetailByIDSelector = (state) =>
  state.RoomInspectionDetailByID;

export const { setSelectedID, updateImages, setSelectedRoom, updateCommentsStatus, setInitialState, setIsReInspection } = RoomInspectionDetailByIDSlice.actions;

export default RoomInspectionDetailByIDSlice.reducer;
