import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "Api/Endpoints/endPoints";
import { PostMethod } from "Api/Methods/apiMethods";
import dayjs from "dayjs";

export const GetAllRoomInspectionDetailByDateRange = createAsyncThunk(
  "RoomInspectionDetail/getAllRoomInspectionDetailByDateRange",
  async ({ startDate, endDate }, thunkAPI) => {
    try {
      let { data } = await PostMethod(
        EndPoints.getRoomInspectionDetail,
        JSON.stringify({
          startDate,
          endDate,
        })
      );
      if(data.isSuccess) return data.data;
      else return {today: [], all: [], completed: []};
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

const initialState = {
  selectedDateRange: {
    startDate: dayjs(new Date()).subtract(6, 'day').toISOString(),
    endDate: dayjs(new Date()).toISOString(),
  },
  filter : false,
  filterTabel : {
    campusInspectionId : "",
    buildingName : [],
    employeeName : [],
    byDate : ""
  },
  RoomInspectionDetail: {today: [], all: [], completed: []},
  isTabelLoading: false,
  error: false,
};

export const RoomInspectionDetailSlice = createSlice({
  name: "RoomInspectionDetail",
  initialState,
  reducers: {
    setSelectedDateRange: (state, { payload }) => {
      state.selectedDateRange = {...state.selectedDateRange, ...payload};
    },
    setFilterTabel: (state, { payload }) => {
      let filterTabel = {...state.filterTabel, ...payload};
      let filter = true;
      if(JSON.stringify(filterTabel) === '{"campusInspectionId":"","buildingName":[],"employeeName":[],"byDate":""}') filter = false;
      console.log(filter);
      state.filter = filter;
      state.filterTabel = filterTabel;
    },
    setFilterTabelInitialValue : (state, action) => {
      state.filter = false;
      state.filterTabel = initialState.filterTabel;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        GetAllRoomInspectionDetailByDateRange.fulfilled,
        (state, { payload }) => {
          state.RoomInspectionDetail = payload;
          state.isTabelLoading = false;
          state.filterTabel = initialState.filterTabel;
          state.filter = false;
        }
      )
      .addCase(GetAllRoomInspectionDetailByDateRange.pending, (state) => {
        state.isTabelLoading = true;
      })
      .addCase(
        GetAllRoomInspectionDetailByDateRange.rejected,
        (state, action) => {
          state.isTabelLoading = false;
          state.error = true;
        }
      );
  },
});

export const RoomInspectionDetailSelector = (state) =>
  state.RoomInspectionDetail;

export const { setSelectedDateRange, setFilterTabel, setFilterTabelInitialValue } = RoomInspectionDetailSlice.actions;

export default RoomInspectionDetailSlice.reducer;
