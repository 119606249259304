import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useField } from 'formik';
import React from 'react'

export default function RadioButton({ name = "", data, label , error, touched, disabled=false, ...rest}) {
    
    return (
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup
          id={name}
          {...rest}
          aria-labelledby="demo-radio-buttons-group-label"
          row
        >
          {data.map((item,index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              disabled={disabled}
              control={<Radio />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
  
  export function FormRadioButton({name , data, label }) {
    const [field, obj, helper] = useField(name);
    return <RadioButton {...field} name={name} data={data} onChange={(_, val) => helper.setValue(val)} label={label} error={obj.err} touched={obj.touched} />;
  }