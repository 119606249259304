import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "../../Api/Endpoints/endPoints";
import { GetMethod } from "../../Api/Methods/apiMethods";

const initialState = {
    unScoredInspections: [],
    isGetAllLoading: false,
    SelectedUncoredInspection: [],
}

export const GetAllUnscoredInspection = createAsyncThunk('UnscoredInspection/getAllUnscoredInspection', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getUnscoredInspection)
        return res.data.data ?? []

    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})

export const UnscoredInspectionSlice = createSlice({
    name: "UnscoredInspection",
    initialState,
    reducers: {
        setSelectedUnscoredInspection: (state, action) => {
            state.SelectedUncoredInspection = action.payload;
        },
        clearID: (state, action) => {
            state.SelectedUncoredInspection = []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllUnscoredInspection.fulfilled, (state, action) => {
                state.unScoredInspections = action.payload
                state.isGetAllLoading = false
            })
            .addCase(GetAllUnscoredInspection.pending, (state, action) => {
                state.isGetAllLoading = true
            })
            .addCase(GetAllUnscoredInspection.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
    }


})

//Selectors
export const UnscoredInspectionSelector = (state) => state.UnscoredInspection


//Actions
export const { setSelectedUnscoredInspection, clearID } = UnscoredInspectionSlice.actions;



//Reducer
export default UnscoredInspectionSlice.reducer;