import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod } from "../../Api/Methods/apiMethods";

export const getallhightouchsurfacesApi = async () => {
    try {
        let res = await GetMethod(EndPoints.getAllHighTouchSurface)
        return res.data
    } catch (error) {
        return error;
    }
}

export const GetAllHighTouchSurface = createAsyncThunk("hightouchsurfaces/getallhightouchsurfaces", getallhightouchsurfacesApi)

export const GetHighTouchSurfaceById = createAsyncThunk(
    "hightouchsurfaces/gethightouchsurfacesbyId",
    async (id, thunkAPI) => {
        try {
            let response = await GetMethod(EndPoints.getHighTouchSurfaceById(id));
            return response.data.data
        }
        catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue({ error: error });
        }


    }
);

const initialState = {
    open: false,
    isEdit: false,
    isLoading: false,
    isGetAllLoading: true,
    HighTouchSurfaces: [],
    UpdateHighTouchSurfaces: {

    },
    HighTouchSurfacesbyId: {

    }

}

export const hightouchsurfacesSlice = createSlice({
    name: "hightouchsurfaces",
    initialState,
    reducers: {
        openModel: (state) => {
            state.open = true
        },
        closeModel: (state) => {
            state.open = false
        },
        EditFalse: (state) => {
            state.isEdit = false
            state.HighTouchSurfacesbyId = {}
        },
        EditHighTouchSurface: (state, { payload }) => {
            state.HighTouchSurfaces = { data: state.HighTouchSurfaces.data.map((item => item.id === payload.data.id ? payload.data : item)) }
            state.UpdateHighTouchSurfaces[payload.data.id] = { id: payload.data.id, isAvailable: payload.data.isAvailable };
        },
        EditAllHighTouchSurfaces: (state, { payload }) => {
            state.HighTouchSurfaces = { data: payload.allWithUpdated };
            state.UpdateHighTouchSurfaces = payload.updatedData.reduce((pre, curr) => {pre[curr.id]  = {id : curr.id, isAvailable : curr.isAvailable }; return pre;}, {});
        },
        ClearUpdate: (state) => {
            state.UpdateHighTouchSurfaces = {}
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetAllHighTouchSurface.fulfilled, (state, action) => {
                state.HighTouchSurfaces = action.payload
                state.isGetAllLoading = false

            })
            .addCase(GetAllHighTouchSurface.rejected, (state, action) => {
                state.isGetAllLoading = false
            })
            .addCase(GetHighTouchSurfaceById.fulfilled, (state, action) => {
                state.isLoading = false
                state.HighTouchSurfacesbyId = action.payload
            })
            .addCase(GetHighTouchSurfaceById.pending, (state, action) => {
                state.isEdit = true
                state.isLoading = true
                state.open = true
            })
            .addCase(GetHighTouchSurfaceById.rejected, (state, action) => {
                state.isLoading = false
                state.HighTouchSurfacesbyId = {}
            })
    }

});

//Selectors
export const HighTouchSurfacesSelector = (state) => state.hightouchsurfaces

export const { openModel, closeModel, EditFalse, ClearUpdate, EditHighTouchSurface, EditAllHighTouchSurfaces } = hightouchsurfacesSlice.actions;

export default hightouchsurfacesSlice.reducer;
