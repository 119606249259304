import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EndPoints } from "../../Api/Endpoints/endPoints";
import { GetMethod } from "../../Api/Methods/apiMethods";
import { setUpateObject } from "Redux/Update/UpdateSlice";


export const getAllInspectionCategories = createAsyncThunk('inspectioncategories/getallinspectioncategories', async (_, thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getInspectionCategoriesAll)
        return res.data.data;
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error });
    }
})

const initialState = {
  isLoading: false,
  isGetAllLoading: true,
  isEdit: false,
  editValue: {},
  error: false,
  Categories: [],
  UpdateCategories: {},
};


const inspectionCategoriesSlice = createSlice({
  name: "inspectioncategories",
  initialState,
  reducers: {
    setEdit: (state, { payload }) => {
      state.isEdit = true;
      state.editValue = payload;
    },
    removeEdit: (state) => {
      state.isEdit = false;
      state.editValue = {};
    },
    EditCategory: (state, { payload }) => {
      state.Categories = payload.allWithUpdated;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAllInspectionCategories.pending, (state) => {
        state.isGetAllLoading = true;
        state.error = false;
      })
      .addCase(getAllInspectionCategories.fulfilled, (state, action) => {
        state.isGetAllLoading = false;
        state.Categories = action.payload || [];
        state.error = false;
      })
      .addCase(getAllInspectionCategories.rejected, (state, action) => {
        state.isGetAllLoading = false;
        state.Categories = [];
        state.error = true;
      });
  },
});

//Selectors
export const InspectionCategorySelector = (state) => state.inspectioncategories;

//Actions
export const { EditACategory, EditCategory, setEdit, removeEdit } =
  inspectionCategoriesSlice.actions;

//Reducer
export default inspectionCategoriesSlice.reducer;