import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SetAuthorization } from "Api/Headers/headers";
import { EndPoints } from "Api/Endpoints/endPoints";
import { GetMethod } from "../../Api/Methods/apiMethods";

export const GetCampusById = createAsyncThunk(
    "campus/getCampusbyId",
    async ({ callBack, CampusID }, thunkAPI) => {
        try {
            let response = await callBack();
            return { data: response.data, CampusID };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
);

export const getAccountTypes = createAsyncThunk('campus/account-types', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getAccountTypes)
        return res.data.data ?? []

    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})

export const GetAllCampus = createAsyncThunk('campus/getallCampus', async (thunkAPI) => {
    try {
        let res = await GetMethod(EndPoints.getCampusAll)
        let subCampus = [];
        res.data.data.map((el) => el.subCampus.length ? subCampus.push(...el.subCampus) : el)
        return {
            data: [...res?.data?.data,...subCampus]
        }

    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue({ error: error });
    }
})

const initialState = {
    selectedAccountType: 0,
    selectedAccountTypeError: false,
    isAdded: false,
    open: false,
    isEdit: false,
    currentId: null,
    isLoading: false,
    campusArray: [],
    CampusData: {
    },
    accountTypes: [],
    campus: {},
    name: "",
    Location: "",
    SelectId: 0,
    authorization: "",
    isAmpm: true,
}

export const campusSlice = createSlice({
    name: "campus",
    initialState,
    reducers: {
        toggle: (state) => {
            state.isAdded = !state.isAdded;
        },
        toggletrue: (state) => {
            state.open = true;
        },
        togglefalse: (state) => {
            state.open = false
        },
        isEditfalse: (state) => {
            state.isEdit = false;
            state.CampusData = {};
            state.currentId = null;
        },
        setNew: (state) => {
            state.isEdit = false;
            state.open = true;
            state.CampusData = {};
            state.currentId = null;
        },
        setcampusitem: (state, action) => {
            state.campus = action.payload
        },
        setCampusname: (state, action) => {
            state.name = action.payload

        },
        setcampusLocation: (state, action) => {
            state.Location = action.payload

        },
        setSelectedID: (state, action) => {
            state.SelectId = action.payload
        },
        setCampusToken: (state, action) => {
            state.authorization = action.payload
        },
        SwitchAMPM: (state, action) => {
            state.isAmpm = action.payload
        },
        setSelectedAccountType: (state, { payload }) => {
            state.selectedAccountType = payload;
        },
        setSelectedAccountTypeErrorTrue: (state) => {
            state.selectedAccountTypeError = true;
        },
        setSelectedAccountTypeErrorFalse: (state) => {
            state.selectedAccountTypeError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAccountTypes.fulfilled, (state, action) => {
            state.accountTypes = action.payload
            state.isGetAllLoading = false
        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.isGetAllLoading = false
        })
        builder.addCase(GetCampusById.fulfilled, (state, { meta, payload }) => {
            state.isLoading = false;
            state.CampusData = { CampusData: payload.data };
        });
        builder.addCase(GetCampusById.pending, (state, { meta }) => {
            state.isEdit = true;
            state.open = true;
            state.isLoading = true;
            state.currentId = meta.arg.CampusID;
        });
        builder.addCase(GetCampusById.rejected, (state, { meta, payload }) => {
            state.isEdit = false;
            state.open = false;
            state.CampusData = {};
            state.currentId = null;
            state.isLoading = true;
        });
        builder.addCase(GetAllCampus.fulfilled, (state, { payload }) => {
            state.campusArray = payload.data
        });

    },
});

export const CampusSelector = (state) => state.campus

export const {
    toggle,
    toggletrue,
    togglefalse,
    isEditfalse,
    setNew, setCampusname,
    setcampusLocation, setcampusitem, setSelectedID,
    setCampusToken, SwitchAMPM, setSelectedAccountType,
    setSelectedAccountTypeErrorFalse, setSelectedAccountTypeErrorTrue } = campusSlice.actions;

export default campusSlice.reducer;

