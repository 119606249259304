import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { EndPoints } from '../../Api/Endpoints/endPoints'
import { GetMethod, PostMethod } from "../../Api/Methods/apiMethods";


export const GetAvailableQuery = createAsyncThunk(
    "ReportBuilder/getAvailableQuery",
    async (_, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getAllReportBuilder)
            // const [currenId] = res?.data?.data ?? []
            // console.log({res});
            // const query = await GetMethod(EndPoints.getqueryColumns(currenId?.id))
            return {
                data: res?.data?.data ?? [],
                // query: query?.data?.data ?? {},
                // id: currenId?.id
            }

        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const GetQueryByID = createAsyncThunk(
    "ReportBuilder/GetQuerybyID",
    async (id, thunkAPI) => {
        try {
            const res = await GetMethod(EndPoints.getQueryColumns(id))
            return res.data.data ?? {}

        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const GetConfigs = createAsyncThunk(
    "ReportBuilder/GetParams",
    async (_, thunkAPI) => {
        try {
            const [chartTypes, reportCategories] = await Promise.all([
                GetMethod(EndPoints.getReportType),
                GetMethod(EndPoints.getReportCategories)
            ])
            return {
                chartTypes: chartTypes.data.data ?? [],
                reportCategories: reportCategories.data.data ?? []
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const getReportDetailByReportId = createAsyncThunk(
    "ReportBuilder/getReportDetailByReportId",
    async (queryParams, thunkAPI) => {
        try {  
            const {data} =  await GetMethod(EndPoints.getReportDetailByReportId(queryParams.id));
            if(data.isSuccess){
                const body = {
                  sql_Text: data.data.queryText,
                  queryParams: queryParams.queryParams ?? [],
                };
                    let response = await PostMethod(EndPoints.fetchPreview, body);
                
                return {
                    reportData : JSON.parse(response.data.data),
                    ...data.data,
                    chartConfig : JSON.parse(data.data.chartConfig),
                    tableConfig : JSON.parse(data.data.tableConfig),
                    queryParameters : data.data.queryParameters
                }
            }
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


let initialReport = { preview: false };

const initialState = {
    Query: [],
    isLoading: false,
    currentQueryId: null,
    selectedQuery: {

    },
    reportTypes :[],
    sqlQuery: "",
    report : initialReport,
    reportData : [],
    isEdit : false,
    isFullPageLoad : false

}

export const ReportBuilderSlice = createSlice({
    name: "ReportBuilder",
    initialState,
    reducers: {
        openModel: (state, action) => {
            state.open = true
        },
        closeModel: (state, action) => {
            state.open = false
        },
        setcurrentQueryId: (state, action) => {
            state.currentQueryId = action.payload
        },
        clearQueryId: (state, action) => {
            state.currentQueryId = null
        },
        addNew: (state, action) => {
            state.currentQueryId = null
            state.selectedQuery = []
            // state.report = initialReport;
        },
        setReport : (state, { payload}) => {
            state.report = payload;
        },
        setreportData : (state, { payload}) => {
            state.reportData = payload;
        },
        
        resetReportBuilderState : () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(GetAvailableQuery.fulfilled, (state, { payload }) => {
                state.Query = payload.data
                // state.selectedQuery = payload.query
                // state.sqlQuery = payload.query.sqlQuery
                // state.currentQueryId = payload.id
            })
            .addCase(GetAvailableQuery.rejected, (state, { payload, meta }) => {
                state.Query = []
            })
            .addCase(GetQueryByID.fulfilled, (state, { payload, meta }) => {
                state.selectedQuery = payload;
                state.currentQueryId = meta.arg;
                // state.report = {...initialReport, currentQueryId : meta.arg };
                state.sqlQuery = payload.sqlQuery
                state.isLoading = false;
            })
            .addCase(GetQueryByID.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(GetQueryByID.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(GetConfigs.fulfilled, (state, { payload, meta }) => {
                state.reportTypes = payload.chartTypes;
                state.reportCategories = payload.reportCategories;
            })
            .addCase(getReportDetailByReportId.pending, (state, action) => {
                state.isFullPageLoad = true
                state.error = false
                state.isEdit = true
            })
            .addCase(getReportDetailByReportId.rejected, (state, action) => {
                state.isFullPageLoad = false
                state.error = true
            })
            .addCase(getReportDetailByReportId.fulfilled, (state, { payload, meta }) => {
                state.currentQueryId = payload.queryId;
                state.selectedQuery = {queryParameters : payload.queryParameters, columnNames : payload.columnNames};
                state.sqlQuery = payload.queryText;
                state.report ={...payload,   preview: true};
                state.reportData = payload.reportData;
                state.isFullPageLoad = false;
                state.error = false
            })
    }

});

//Selectors
export const ReportBuilderSelector = (state) => state.ReportBuilder

export const { openModel, closeModel, clearQueryId, setcurrentQueryId, addNew, setReport, setreportData, resetReportBuilderState
    // setReport,setInitialReport
  } = ReportBuilderSlice.actions;

export default ReportBuilderSlice.reducer;
